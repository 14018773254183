.settings-page {
  width: 100%;
}

.settings-page .page-header-area {
  height: 88px;
}

.settings-main {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  height: 100vh;
}

.settings-main .settings-tabs {
  display: flex;
  width: 224px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lightest, #f3f3f5);
}

.settings-page .tab-content {
  flex: 1;
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.subscription-container .section h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.booster-products {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.booster-product {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 12px;
  border: 1px solid rgba(92, 104, 128, 0.45);
  background: var(--Plus-White, #fffefc);
  box-shadow: 2px 2px 1px 0px rgba(92, 104, 128, 0.1);
}

.booster-product h3 {
  font-weight: 700;
}

.booster-product button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subscription-container .section .info-pair:first-child {
  border-left: none;
}

.cancel-line {
  margin-top: 14px;
}

.settings-page .page-inner-wrapper {
  width: 960px;
}

.crm-settings {
  margin-top: 24px;
}

.crm-settings p {
  margin-bottom: 0px;
}

.crm-settings .checkboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px;
  margin-bottom: 24px;
}

.company-settings .settings-set form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 0px;
}

.company-settings .settings-set form label {
  font-weight: 500;
}

.save-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.settings button svg {
  color: var(--Dream-Yellow);
}

.section.your-details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
}

.section.your-details .wrapped-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.section.your-details .wrapped-heading h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.section.your-details .wrapped-heading button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.section.your-details .dream-side-modal-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.thin-form-group {
  display: flex;
  width: 540px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.section.your-details input {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.change-password {
  cursor: pointer;
  display: flex;

  align-items: center;
  gap: 12px;
  border-radius: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.change-password svg,
.change-password .spinner-border {
  width: 24px;
  height: 24px;
}

.change-password .danger {
  color: var(--Dark-Red);
  display: flex;
  align-items: center;
  gap: 8px;
}

.team-loading,
.subscription-loading {
  width: 100%;
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-container .subscription-details-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-top: 12px;

  border-radius: 12px;
  background: var(--Lisa-Yellow, #f7f5e9);
}

.subscription-details-wrapper .inner {
  display: flex;
  padding: 12px 0px;
  align-items: flex-start;
  gap: -1px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.subscription-container .section .info-pair {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  border-left: 1px solid var(--Grays-Light, #c1c5cd);
}
.subscription-container .section .info-pair .label {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.subscription-container .section .info-pair .value {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.subscription-products {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.subscription-products .product {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid rgba(92, 104, 128, 0.45);
  background: var(--Plus-White, #fffefc);
  box-shadow: 2px 2px 1px 0px rgba(92, 104, 128, 0.1);
  max-width: 290px;
}

.subscription-products .product:first-child {
  border-top: 20px solid var(--Light-Green);
}

.subscription-products .product:nth-child(2) {
  border-top: 20px solid var(--Light-Red);
}

.subscription-products .product:nth-child(3) {
  border-top: 20px solid var(--Dream-Yellow);
}

.product h3 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 111.111% */
}

.product .heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.product .heading p:last-child {
  opacity: 0.8;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.product p.credits-view {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.product p.credits-view b {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.product hr {
  height: 1px;
  background: var(--Grays-Medium, #838b9b);
  padding: 0px;
  width: 100%;
  margin: 0px;
}

.product p.best-for {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.product .button-wrapper {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}

.product .button-wrapper button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);

  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.products-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.subscription-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  align-self: stretch;
}

.subscription-container .helpful-call-team {
  margin-top: 16px;
}

.subscription-container .booster {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.products-outer-wrapper .reverse {
  margin-top: -12px;
}

.product.active-subscription {
  border-radius: 12px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: linear-gradient(0deg, #fffefc 0%, #e6e8eb 100%);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

button.current-plan {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: rgba(0, 13, 38, 0.15);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid rgba(0, 13, 38, 0.15) !important;
}

.team-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.team-settings .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.team-settings .heading button {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.team-settings .heading h3 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.team-settings .team-table-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.team-table-wrapper .pseudo-table {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.team-table-wrapper .pseudo-table .table-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.team-table-wrapper .pseudo-table .table-head {
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.team-table-wrapper .pseudo-table .table-head > * {
  flex: 1 0 0;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.team-table-wrapper .pseudo-table .team-member > * {
  flex: 1 0 0;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.team-table-wrapper .pseudo-table .team-member {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
  border-radius: 8px;
}

.team-table-wrapper .tag.positive {
  display: inline;
  padding: 2px 8px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    #64b6ac;
}
.add-team-member {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.add-team-member input[type='text'],
.add-team-member input[type='email'] {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.add-team-member .rapid-switch {
  display: flex;
  align-items: center;
  gap: -1px;
  align-self: stretch;

  border-radius: 12px;
  margin-top: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.add-team-member .rapid-switch .option {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;

  background: var(--Lisa-Yellow, #f7f5e9);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  cursor: pointer;
}

.add-team-member .option.active {
  background: var(--Dream-Yellow, #ffd601);
}

.add-team-member .rapid-switch .option:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.add-team-member .rapid-switch .option:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.pseudo-table .table-body .delete {
  color: var(--Dark-Red, #931f1d);
  cursor: pointer;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.member-delete-modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.member-delete-modal-wrapper .shower {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Lisa-Yellow, #f7f5e9);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.member-delete-modal-wrapper p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.member-delete-modal-wrapper input {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.product-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.product-form p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-loading-area {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 64px;
  align-items: center;
}

.product-empty-state-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: calc(100vh - 180px);
}

.product-empty-state-wrapper .empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border: none;
  text-align: center;
}

.product-empty-state-wrapper .empty-state > svg {
  width: 40px;
  height: 40px;
}

.product-empty-state-wrapper .empty-state h6 {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-empty-state-wrapper .empty-state p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 400px;
}

.product-empty-state-wrapper .empty-state > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.product-empty-state-wrapper .empty-state > button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
}

.integrations-page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.integration-set {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.integrations-page h2 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.integrations-page .integration-set h3 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.integrations-page h2 + p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.integrations-page h3 + p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.integrations-page .link-set {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.integrations-page .link-set button {
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--White, #fff);
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  align-items: center;
  color: #086788;
  display: flex;
  gap: 4px;
}

.integrations-page .link-set button svg {
  color: var(--Light-Blue);
  width: 24px;
  height: 24px;
}

.integrations-page .settings-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 8px;
}

.settings-main .nav-link.disabled {
  opacity: 0.5;
}

.web-tracking-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.web-tracking-settings .heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.web-tracking-settings .heading p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.web-tracking-settings .tracking-wrapper {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Grays-Lightest, #f3f3f5);
  width: 100%;
}

.web-tracking-settings .tracking-wrapper label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.web-tracking-settings .track-result {
  display: flex;
  align-items: center;
  gap: 8px;
}

.track-result .dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}

.track-result .dot.negative {
  background: var(--Dark-Red, #931f1d);
}

.track-result .dot.positive {
  background: var(--Light-Green, #64b6ac);
}
.track-result span {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.tracking-wrapper .button-wrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
}

.tracking-wrapper .button-wrapper button {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: var(--White, #fff);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.section .how-does-it-work-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.section .how-does-it-work-wrapper h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.how-does-it-work-wrapper .boxes {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
}

.how-does-it-work-wrapper .boxes .box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.how-does-it-work-wrapper .boxes .box span {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 20px;
  background: var(--Light-Red, #f67e7d);
}

.how-does-it-work-wrapper .boxes .box h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.how-does-it-work-wrapper .boxes .box p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.tracking-tag-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.tracking-tag-info h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.tracking-tag-info p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.tracking-tag-info .code-area {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
  position: relative;
  flex-direction: column;
  width: 100%;
}
.tracking-tag-info .code-area pre {
  color: #000;
  font-family: monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0px;
}

.tracking-tag-info button {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: var(--White, #fff);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  align-self: flex-end;
}

.tracking-tag-info button svg {
  width: 14px;
  height: 14px;
  color: black;
}
