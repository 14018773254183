.funnel-run-number-companies {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.funnel-run-number-companies > div {
  width: 100%;
}

.filter-warning {
  margin-top: 24px;
}

.funnel-run-number-companies label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filter-warning,
.import-information {
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.funnel-run-number-companies > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  align-self: stretch;
}

.number-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 173px;
}

.number-input-wrapper button {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 24px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: #fff;
}

.number-input-wrapper button:hover svg {
  color: white;
}

.number-input-wrapper button svg {
  color: var(--Performa-Black, #152647);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
