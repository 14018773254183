.conversation-opts-form,
.pause-conversation-group {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.pause-conversation-group .form-check {
  display: flex;
  align-items: center;
  gap: 12px;
}

.pause-conversation-group .form-check-label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.pause-conversation-group .form-check {
  margin-top: 12px;
}

.disabled-pause-section > div:first-child {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  opacity: 0.5;
}

.disabled-pause-section .already-paused {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--Lisa-Yellow, #f7f5e9);
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-top: 12px;
}

.reactivate-button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Plus-White, #fffefc);
  margin-top: 12px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
