.funnel-view-page {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.next-steps-wrapper h5 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.next-steps-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  width: 100%;
}

.next-step {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.next-step svg {
  width: 24px;
  height: 24px;
}

.next-step.funnel svg {
  color: var(--Dark-Blue);
}

.next-step .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.next-step .right h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.next-step .right p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.next-step .right span {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;
  cursor: pointer;
}

.funnel-view-page .alert-framing-wrapper.hidden {
  opacity: 0;
}

.funnel-view-page .alert-framing-wrapper {
  align-self: stretch;
  opacity: 1;
  position: absolute;
  left: 32px;
  bottom: 49px;
  transition: opacity 1s ease-in-out;
  width: calc(100% - 64px);
  z-index: 1;
}

.funnel-view-page .alert-framing-wrapper .alert-info {
  border-radius: 8px;
  border: 1px solid var(--Light-Blue, #07a0c3);
  background: linear-gradient(
      0deg,
      rgba(7, 160, 195, 0.1) 0%,
      rgba(7, 160, 195, 0.1) 100%
    ),
    #fff;

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.action-happening {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
}

.action-happening p {
  flex: 1 0 0;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.action-happening span {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.datapoint-selector-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.datapoint-selector-modal .header-area {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.datapoint-selector-modal .header-area p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.datapoint-selector-modal .empty-state button,
.datapoint-selector-modal .add-new-wrapper button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.datapoint-selector-modal .add-new-wrapper button {
  width: 100%;
}

.datapoint-selector-modal .datapoints-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.datapoint-selector-modal .datapoints-wrapper .datapoint-frame {
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.datapoint-selector-modal .datapoints-wrapper .datapoint-frame .delete-line {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: space-between;
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.1) 0%,
      rgba(246, 126, 125, 0.1) 100%
    ),
    #fff;
}

.datapoint-selector-modal .datapoints-wrapper .datapoint-frame .delete-line b {
  font-weight: 500;
}

.datapoint-selector-modal
  .datapoints-wrapper
  .datapoint-frame
  .delete-line
  span {
  color: var(--Dark-Blue, #086788);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.datapoint-selector-modal .datapoints-wrapper .datapoint-item {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  cursor: pointer;
}

.datapoint-selector-modal .datapoint-item .name {
  font-weight: 500;
}

.datapoint-selector-modal .datapoint-frame.active {
  background: var(--Dream-Yellow);
  border: 1px solid var(--Wallstreet-Black);
  font-weight: 500;
}

.datapoint-selector-modal .datapoint-frame.active .delete-button {
  visibility: hidden;
}

.datapoint-selector-modal .datapoint-item .delete-button svg {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  color: var(--Light-Red, #f67e7d);
}
.datapoint-selector-modal .datapoint-item .delete-button {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  flex: none;
  margin-left: 16px;
}
.datapoint-selector-modal .datapoint-item .delete-button.active,
.datapoint-selector-modal .datapoint-item .delete-button:hover {
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.1) 0%,
      rgba(246, 126, 125, 0.1) 100%
    ),
    #fff;
}

.datapoint-selector-modal .datapoint-item.active .select-datapoint {
  color: var(--Wallstreet-Black);
}

.datapoint-selector-modal .select-datapoint {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.orgs-area.dream-table-wrapper .rdt_TableCell {
  font-size: 14px;
}

.orgs-area.dream-table-wrapper .rdt_TableCell .spinner-border {
  color: var(--Light-Blue);
}

.orgs-area .datapoints-line {
  overflow-x: scroll;
  width: 100%;
}

.orgs-area .datapoints-line .inner-wrapper-dp {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent wrapping */
  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lightest, #f3f3f5);
}

.orgs-area .datapoints-line .inner-wrapper-dp .datapoint-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.orgs-area .datapoints-line .inner-wrapper-dp > * {
  flex-shrink: 0;
}

.list-delete-button {
  display: flex;
  min-height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.orgs-area .datapoints-line .datapoint-button {
  display: flex;
  min-height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.orgs-area .datapoints-line .datapoint-button svg {
  color: var(--Light-Red);
}

.orgs-area.dream-table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.orgs-area .dream-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.orgs-area .dream-table-container > *:first-child {
  flex: 1;
}

.funnel-view-page .lower-loading-area {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.dp-loading-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.funnel-view-page .page-split {
  display: flex;
  align-items: stretch;
  gap: 32px;
  align-self: stretch;
  flex: 1;
}

.funnel-view-page .main-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
}

.funnel-view-page .main-area .main-area-body {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.funnel-view-page .page-split .center-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.funnel-view-page .page-split .right-side {
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.funnel-view-page .stages {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  gap: 12px;
  width: 100%;
  padding: 8px 24px;
  background: var(--Grays-Lightest, #f3f3f5);
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.funnel-view-page .stages > * {
  border-radius: 8px;
}

.funnel-view-page .stages > .stage-holder {
  display: flex;
  gap: 12px;
  flex: 1;
}

.funnel-view-page .stages > .stage-holder > * {
  border-radius: 8px;
}

.funnel-view-page .stages > *:nth-child(2) {
  flex: 1;
}

.funnel-view-page .columns-button {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.stages .stage-holder .stage {
  display: flex;
  align-items: stretch;
  height: 100%;
  width: 100%;
}

.stages .stage-holder .stage .stage-entry {
  width: 100%;
  border-radius: 8px;
}

.stages .stage-entry.smaller {
  max-width: 230px;
  background: var(--Grays-Lightest, #f3f3f5);
}
.stages .stage-entry.medium {
  max-width: 400px;
  background: var(--Grays-Lightest, #f3f3f5);
}

.datapoints-list.stage-entry {
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.funnel-view-page .stage-entry {
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  display: flex;
  padding: 8px 16px;

  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.funnel-view-page .stage-entry.smaller,
.funnel-view-page .stage-entry.medium,
.funnel-view-page .stage-entry > div.set > p.description {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.funnel-view-page .stage-entry > div.set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.funnel-view-page .stage-entry > div.set > b {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.funnel-view-page .stage-entry .edit-line {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.funnel-view-page .stage-entry .edit-line svg {
  color: var(--Light-Blue);
}

.funnel-view-page .stage-entry .edit-line {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.orgs-area {
  width: 100%;
}

.center-area .dream-table-wrapper .rdt_TableHeadRow {
  background: #fff;
}
.center-area .dream-table-wrapper .rdt_TableRow,
.center-area .dream-table-wrapper .rdt_TableHeadRow {
  margin-left: 24px;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.center-area .dream-table-wrapper .rdt_Table {
  background: #fff;
}

.center-area .dream-table-wrapper .rdt_Pagination {
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.center-area .rdt_TableCell .wrappable {
  text-wrap: wrap;
  cursor: pointer;
}

.center-area .rdt_TableCell {
  padding: 12px;
}

.rdt_TableCell:hover .trunc {
  display: inline;
}

.trunc {
  display: none;
  padding: 4px;
  align-items: center;
  align-self: flex-start;
  gap: 2px;
  margin-left: 2px;
  cursor: pointer;
}

.trunc svg {
  height: 12px;
}

.stats-header .stats-area {
  display: flex;
  align-items: center;
  gap: 24px;
}

.stats-area .label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.stats-area .value {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.stats-area.inactive p.value {
  text-decoration-line: underline;
  cursor: pointer;
}

.list-area .psuedo-table-wrapper .header-line {
  display: flex;
  padding: 12px 48px 12px 24px;
  margin-left: 16px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  justify-content: space-between;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.list-area .psuedo-table-wrapper .header-line > * {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.list-area .funnel-list-view .funnel-list-item {
  display: flex;
  padding: 20px 24px;
  padding-right: 48px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.list-area .funnel-list-view .funnel-list-item a {
  text-decoration: none;
}
.list-area .funnel-list-view .funnel-list-item a:hover {
  text-decoration: underline;
}

.list-area .funnel-list-view .funnel-list-item:hover {
  background: var(--Very-Light-Grey);
}

.list-area .funnel-list-view .funnel-list-item h5 {
  width: 288px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.list-area div.checkbox-wrapper {
  width: 24px;
  height: 24px;
}

.list-area div.checkbox-wrapper input {
  width: 24px;
  height: 24px;
}
.list-area .header-line .title {
  width: 288px;
}

.header-line .status,
.header-line .companies {
  width: 150px;
}

.list-area .funnel-list-view .funnel-list-item .funnel-list-set {
  display: flex;
  width: 150px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.list-area .header-line .pre-final {
  width: 184px;
}

.list-area .header-line .final {
  width: 20px;
}

.list-area .funnel-list-view .funnel-list-item .funnel-list-set p {
  margin-bottom: 0px;
}

.list-area .funnel-list-view .funnel-list-item .funnel-list-set p.label {
  color: var(--Dark-Grey, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.list-area .funnel-list-view .funnel-list-item .funnel-list-set p.value {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.list-area .funnel-list-view .funnel-list-set.with-logos {
  width: 184px;
}

.list-area .funnel-list-view .funnel-list-set.final {
  width: 20px;
}

.list-area .funnel-list-view .funnel-list-set.final svg {
  color: var(--Dark-Blue);
}

.list-area .logo-set {
  display: flex;
  align-items: center;
  gap: 8px;
}

.list-area .logo-set .logo-selection {
  display: flex;
  align-items: center;
}

.list-area .logo-set .logo-selection .logo {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: lightgray 50% / cover no-repeat;
}

.list-area .logo-set .logo-selection .logo:not(:first-of-type) {
  margin-left: -4px;
}

.list-area .and-more {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.psuedo-table-wrapper .selected-info {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  background: linear-gradient(
      0deg,
      rgba(7, 160, 195, 0.1) 0%,
      rgba(7, 160, 195, 0.1) 100%
    ),
    #fff;
  margin-left: 16px;
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.psuedo-table-wrapper .selected-info:hover {
  cursor: pointer;
  text-decoration: underline;
}

.psuedo-table-wrapper .selected-info svg {
  width: 24px;
  height: 24px;
}

/* Custom checkbox styles */
.sexy-checkbox-wrapper
  input[type='checkbox']
  .psuedo-table-wrapper
  input[type='checkbox'],
.sexy-checkbox {
  appearance: none;
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #c1c5cd; /* Grays-Light */
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.sexy-checkbox-wrapper input[type='checkbox']:checked,
.psuedo-table-wrapper input[type='checkbox']:checked,
.sexy-checkbox:checked {
  background-color: #07a0c3; /* Light-Blue */
  border: 1px solid #07a0c3; /* Match border to background color */
  position: relative;
}

.sexy-checkbox-wrapper input[type='checkbox']:checked::after,
.psuedo-table-wrapper input[type='checkbox']:checked::after,
.sexy-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 8px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.datapoints-line .separator {
  width: 1px;
  align-self: stretch;
  background: var(--Grays-Light, #c1c5cd);
}

.datapoint-button svg {
  width: 24px;
  height: 24px;
}

.orgs-area .datapoints-line .datapoint-button.light {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.research-point-tag {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;

  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.1) 0%,
      rgba(246, 126, 125, 0.1) 100%
    ),
    #fff;
}

.research-point-tag svg {
  width: 16px;
  height: 16px;
  color: var(--Light-Red);
}

.add-research-column {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.add-research-column svg {
  color: var(--Light-Red);
}

.download-csv-button {
  display: flex;
  padding: 8px 20px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.stats-header {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;

  padding: 4px 24px;
}

.stats-left {
  display: flex;

  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.red-icon svg {
  color: var(--Light-Red) !important;
}

.yellow-icon svg {
  color: var(--Dream-Yellow) !important;
}

.grey-icon svg {
  color: var(--Pomona-Black, #5c6880) !important;
}

.research-point-choose-modal .selection-set {
  display: flex;
  align-items: center;
  align-self: stretch;

  border-radius: 16px;
  background: #fff;
  margin-top: 8px;
}

.research-point-choose-modal .selector {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.research-point-choose-modal .selector svg {
  width: 32px;
  height: 32px;
  color: var(--Light-Red);
}

.research-point-choose-modal .selector h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.research-point-choose-modal .selector p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.research-point-choose-modal .selector:first-child {
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
}

.research-point-choose-modal .selector.selected svg {
  color: var(--Wallstreet-Black);
}

.research-point-choose-modal .selector.selected {
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.research-point-choose-modal .selector:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-right: 0px;
}

.research-point-choose-modal .selector:first-child.selected + .selector {
  border-left: 1px solid var(--Wallstreet-Black, #000d26);
}

.research-point-choose-modal .selector:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.datapoint-selector-modal svg {
  color: var(--Light-Red);
}

.datapoint-selector-modal button svg {
  color: var(--Light-Red) !important;
}

.datapoint-selector-modal .datapoints-selection-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.datapoint-selector-modal .datapoints-selection-wrapper label {
  font-weight: 500;
}

.template-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.template-left img {
  width: 16px;
  height: 16px;
}

.template-left .sources-line {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}

.template-left .source {
  display: flex;
  align-items: center;
  gap: 8px;
}

.template-left .source:not(:last-child)::after {
  content: '•';
}

.datapoint-item > span:first-child {
  width: 200px;
}
.datapoint-item .sources {
  display: flex;
  gap: 4px;
  flex: 1;
  justify-content: flex-end;
}
.datapoint-item .sources img {
  width: 16px;
  height: 16px;
}

.datapoint-item .credit-cost {
  font-size: 13px;
  font-weight: 400;
  width: 120px;
  text-align: right;
}

.datapoint-button .delete {
  display: none;
}

.datapoint-button:hover .delete {
  display: block;
}

.datapoint-button .delete svg {
  height: 12px;
  width: 12px;
}

.research-point-side-wrapper .form-check-label {
  font-size: 14px;
  font-weight: 400;
}

.research-point-side-wrapper.edit > .dream-side-modal-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tooltip-context a {
  color: var(--Dark-Blue);
  font-weight: 500;
  margin-top: 16px;
}

.popover {
  max-width: 500px;
  z-index: 1;
}

.popover-body {
  width: 400px;
  text-align: center;
}

.orgs-area.dream-table-wrapper .tiny-link-button {
  text-decoration: none;
  font-size: 14px;
}

.not-found-frame {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;

  border-radius: 24px;
  background: var(--Grays-Lighter, #e6e8eb);
}

.not-found-frame svg {
  width: 24px;
  height: 24px;
  color: var(--Grays-Medium, #838b9b);
}

.not-found-frame:hover {
  background: #c1c5cd;
}

.not-found-frame:hover svg {
  color: var(--Pomona-Black, #5c6880);
}

.soft-processing-frame {
  background: var(--Grays-Lightest, #f3f3f5);
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
}
.soft-processing-frame svg {
  color: #838b9b;
  width: 24px;
}
.prioritized .soft-processing-frame svg {
  color: #838b9b;
  width: 24px;
}
.error-frame:hover svg {
  color: #931f1d;
}

.error-frame svg {
  color: var(--Light-Red);
  width: 30px;
  height: 26px;
}

.funnel-button-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
}

.funnel-button-area button {
  display: flex;
  height: 44px;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #fff;
}

.funnel-button-area .full-screen-button,
.funnel-button-area .crm-settings-button {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.funnel-button-area .full-screen-button.active {
  border-radius: 8px;
  color: var(--Pomona-Black);
  border: none;
  background: white;
}

.funnel-button-area .full-screen-button.active svg {
  color: var(--Dream-Yellow);
}

.funnel-button-area .full-screen-button svg,
.funnel-button-area .crm-settings-button svg {
  width: 20px;
  height: 20px;
  color: #5c6880;
}

.page-header-area {
  transition: margin 0.5s ease-in-out;
}
.page-header-area.retreated {
  margin-top: -81px;
}

.page-header-area.retreated + .page-split .dream-table-container > * {
  height: 100%;
}

.big-react-select .country-select__control {
  height: 48px;
}

.big-orgs-loading {
  border-radius: 0px !important;
  border-left: 0px !important;
  /* padding: 128px 0px !important; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-in-row {
  animation: fadeIn 1s cubic-bezier(0.22, 0.68, 0, 1.71);
}

.funnel-view-page .edit-line {
  align-self: center;
}

.funnel-view-page .edit-icon-wrapper {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  align-self: center;
}

.orgs-area .dream-table-container {
  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  margin-left: 16px;
}

.cell-for-display {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.cell-for-display label {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.cell-for-display .context-cell {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  border-radius: 4px;
  background: var(--Grays-Lightest, #f3f3f5);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  overflow: hidden;
  white-space: nowrap;

  max-height: 48px;
}

.cell-for-display .context-cell a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  text-decoration-line: underline;
}

.page-split .action-section button {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  opacity: 0.8;
  border-radius: 8px;
  border: none !important;
  background: var(--Dream-Yellow, #f3f3f5);
  color: var(--Wallstreet-Black, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.page-split .action-section button.delete-button {
  border-radius: 8px;
  background: var(--Grays-Lightest, #f3f3f5);
  opacity: 1;
  color: var(--Dark-Red);
  gap: 4px;
}

.page-split .action-section button.delete-button svg {
  color: var(--Dark-Red);
  height: 16px;
  width: 16px;
}

.add-column-modal .choice-set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.add-column-modal .choice-set > label {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.add-column-modal .choice-set .option {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.add-column-modal .option .card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  cursor: pointer;
}

.add-column-modal .option .card-right p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.add-column-modal .option .card-right p.label {
  font-weight: 500;
}

.add-column-modal .option svg {
  color: #5c6880;
  width: 32px;
  height: 32px;
}

.add-column-modal .option .plus-wrapper svg {
  width: 24px;
  height: 24px;
  color: var(--Light-Blue);
}

.ad-column-config-modal input {
  display: flex;
  height: 52px;
  padding: 12px 8px 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.further-upload-text,
.csv-uploader {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.further-upload-text .main-description {
  font-size: 16px;
}

.schedule-sourcing {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.schedule-sourcing {
  font-size: 14px !important;
}

.schedule.filter-warning {
  margin-top: 0px;
}

.dream-side-modal-form-input-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  background: var(--Lisa-Yellow);
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.dream-side-modal-form-input-loading .spinner-border {
  width: 24px;
  height: 24px;
  color: var(--Light-Blue);
}

.csv-uploader .radio-set {
  display: flex;
  gap: 24px;
  align-items: center;
}

.stats-area.exclusion .icon-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.stats-area.exclusion svg {
  width: 17.5px;
  height: 17.5px;
  flex-shrink: 0;
  color: var(--Pomona-Black, #5c6880);
}

.loading-crm-state {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Lisa-Yellow);
  padding: 24px;
  border-radius: 8px;
}

.loading-crm-state .spinner-border {
  color: var(--Light-Blue);
}

.loading-crm-error {
  border-radius: 4px;
  border: 1px solid #f67e7d;
  background: #f67e7d;
  padding: 24px;
  border-radius: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 12px 20px 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.loading-crm-error svg {
  width: 24px;
  height: 24px;
  color: #000d26 !important;
}

.loading-crm-error span {
  flex: 1;
}

.no-crm-state {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.no-crm-state svg {
  width: 32px;
  height: 32px;
  color: #5c6880 !important;
}

.no-crm-state a {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  text-decoration: none;
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.no-crm-state h6 {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.search-type-select .option-wrapper.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-type-select .option-wrapper.disabled svg {
  color: #838b9b !important;
}
.search-type-select .option-wrapper.disabled * {
  user-select: none;
}

.basic-section.crm-select .info-line {
  margin-bottom: 4px;
}
.basic-section.crm-select .info-line .alert {
  border-radius: 4px;
  border: 1px solid #f67e7d;
  background: #f67e7d;
  padding: 24px;
  border-radius: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 12px 20px 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.basic-section.crm-select .info-line .alert svg {
  width: 24px;
  height: 24px;
  color: #000d26 !important;
}

.basic-section.crm-select .info-line .loading-line {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.basic-section.crm-select .info-line .loading-line .spinner-border {
  color: #07a0c3;
  height: 24px;
  width: 24px;
}

.basic-section.crm-select .info-line .loading-line span {
  flex: 1;
}

.option-wrapper.crm-icons .icon-wrapper svg {
  color: #64b6ac;
}

.option-wrapper.crm-icons.active .icon-wrapper svg {
  color: var(--Wallstreet-Black);
}

.searched-orgs-tag-wrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  height: 100%;
}

.searched-orgs-tag-wrapper .tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 4px 12px;
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(7, 160, 195, 0.3) 0%,
      rgba(7, 160, 195, 0.3) 100%
    ),
    #fff;
}
