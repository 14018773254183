.add-prospect-search-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.add-prospect-search-modal .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.add-prospect-search-modal .header h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.add-prospect-search-modal .header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.add-prospect-search-modal .prospect-search-overview {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.add-prospect-search-modal .search-type {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  cursor: pointer;
}

.add-prospect-search-modal .search-type > svg {
  color: var(--Light-Blue);
  width: 32px;
  height: 32px;
}

.add-prospect-search-modal .search-type .main-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.add-prospect-search-modal .search-type .main-body p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.add-prospect-search-modal .search-type .main-body p:last-child {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.add-prospect-search-modal .search-type.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.add-prospect-search-modal .search-type.active svg {
  color: var(--Wallstreet-Black);
}

.known-job-title-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.known-job-title-modal .form-check-label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.big-react-select-control {
  min-height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid var(--Grays-Light, #c1c5cd) !important;
}

.test-prospect-search .job-titles {
  background: var(--Lisa-Yellow);
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
  line-height: 20px;
}
