.jobs-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.jobs-modal-wrapper .job-item {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.jobs-modal-wrapper .job-item:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top: 0px;
}

.jobs-modal-wrapper .job-item:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.jobs-modal-wrapper .job-item .job-left {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  flex: 1;
}

.jobs-modal-wrapper .job-item svg {
  color: var(--Dark-Blue);
  width: 24px;
  height: 24px;
}

.jobs-modal-wrapper .job-item .view-job {
  color: var(--Dark-Blue);
  cursor: pointer;
}

.job-view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.job-view-wrapper .header > div:first-child {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
  justify-content: space-between;
}

.job-view-wrapper .header {
  display: flex;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
  justify-content: space-between;
}

.job-view-wrapper .set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.job-view-wrapper .set .label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.job-view-wrapper .set .value {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  word-wrap: break-word;
}

.job-view-wrapper .header button {
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.job-view-wrapper .header button svg {
  color: var(--Dark-Blue);
  width: 20px;
  height: 20px;
}

.job-view-wrapper .description-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.job-view-wrapper .description-header > div:first-child {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.job-view-wrapper .description-header small {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.job-view-wrapper .description-header {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;

  background: var(--Grays-Lightest, #f3f3f5);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.job-view-wrapper .description-header svg,
.job-view-wrapper .description-header img {
  width: 32px;
  height: 32px;
  border-radius: 36px;
}

.job-view-wrapper .description-wrapper .main-content-area {
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
