.add-profile-modal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.add-profile-modal .profile-type-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.add-profile-modal .split-selector {
  display: flex;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.add-profile-modal .split-selector .option {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  gap: 16px;
  flex: 1 0 0;
  cursor: pointer;
}

.add-profile-modal .split-selector .option:last-child {
  border-left: 1px solid var(--Grays-Light, #c1c5cd);
}

.add-profile-modal .split-selector .option svg {
  color: var(--Light-Blue);
  width: 28px;
  height: 28px;
}

.add-profile-modal .split-selector .option:last-child svg {
  color: var(--Light-Green);
}

.split-selector .option h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.split-selector .option p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.add-profile-modal .split-selector .option.active svg {
  color: var(--Wallstreet-Black);
}

.add-profile-modal .split-selector .option:first-child.active {
  background: var(--Dream-Yellow, #ffd601);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.add-profile-modal .split-selector .option:last-child.active {
  background: var(--Dream-Yellow, #ffd601);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.add-profile-modal .profile-type-select label {
  margin-bottom: 0px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sdrs.button-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}

.sdrs.button-wrapper button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.step-one-choices-wrapper {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.step-one-choices-wrapper .set {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.step-one-choices-wrapper .set .label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-profile-modal .step-lower.dream-side-modal-form .group > label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-profile-modal .step-lower.dream-side-modal-form label {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-profile-modal .step-lower {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.add-profile-modal .group.radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.empty-warning {
  border-radius: 12px;
  background: var(--Lisa-Yellow);
  padding: 16px;
}
