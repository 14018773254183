.successful-engagements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  h3 {
    color: #000;
    font-family: "Spline Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }

  .engagements-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .engagement-item {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 8px;
      border: 1px solid rgba(92, 104, 128, 0.25);
      background: var(--Plus-White, #FFFEFC);

      /* Container-shadow */
      box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.20);
    }


    .engagement-item .left-side {
      display: flex;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;

      .engagement-name {
        color: var(--Wallstreet-Black, #000D26);
        font-family: "Spline Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }
    }

    .engagement-view {
      color: var(--Dark-Blue, #086788);
      font-family: "Spline Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 125% */
    }
  }

  .engagement-logo img,
  .engagement-logo svg {
    width: 32px;
    height: 32px;
    border-radius: 32px;
  }
}