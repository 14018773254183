.dream-side-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 13, 38, 0.5);
}

.dream-side-modal {
  border-radius: 16px;
  background: var(--Plus-White, #fffefa);
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
  outline: none;
}

.dream-side-modal.ReactModal__Content--after-open {
  transform: translate(0%) !important; /* animate into position */
}

.dream-side-modal.ReactModal__Content--before-close {
  transform: translate(100%) !important; /* animate out of view */
}

.dream-modal-inner {
  display: flex;
  width: 600px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-shrink: 0;
  align-self: stretch;
  height: 100%;
}

.dream-modal-inner-header,
.dream-modal-inner-footer {
  padding: 24px;
}

.dream-modal-inner-header + .dream-modal-inner-body {
  padding-top: 0px;
}

.dream-modal-inner-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.dream-modal-inner-header svg {
  width: 32px;
  height: 32px;
}

.funnel.dream-modal-inner svg {
  color: var(--Light-Blue);
}

.dream-modal-inner-header {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.dream-modal-inner-header .header-with-buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.dream-modal-inner-header .header-with-buttons .buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.dream-modal-inner-header .header-with-buttons .buttons-wrapper button {
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  line-height: normal;
}

.dream-modal-inner-footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.dream-modal-inner-footer button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dream-modal-inner-body {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
}

.transparent-overlay {
  background-color: rgba(
    0,
    0,
    0,
    0
  ) !important; /* Make the background transparent */
}

.inner-opt-group .short {
  width: 50%;
}

.dream-side-modal.large .dream-modal-inner {
  width: 1152px;
}

.dream-modal-inner.opts-menu {
  width: 300px;
  height: auto;
  align-self: flex-start;
}

.dream-side-modal.paired {
  background: transparent;
  box-shadow: none;
  outline: none;
}

.dream-side-modal.paired .dream-modal-inner:not(.opts-menu),
.dream-side-modal.paired .dream-modal-inner .opts-inner {
  border-radius: 16px;
  background: var(--Plus-White, #fffefa);
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
  outline: none;
  overflow: auto;
}

.dream-side-modal.paired .dream-modal-inner .opts-inner {
  padding: 24px;
}

.dream-side-modal.paired .dream-modal-inner.opts-menu {
  height: 100%;
}
.dream-side-modal.paired .dream-modal-inner.opts-menu .dream-modal-inner-body {
  display: flex;
  padding: 0px;
  flex-direction: column;
}

.dream-side-modal.paired
  .dream-modal-inner.opts-menu
  .dream-modal-inner-body
  .opts-lower {
  flex: 1;
}

.dream-side-modal-overlay {
  z-index: 2;
}

.dream-modal-inner-opts-wrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  height: 100%;
}

.dream-opts-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
}

.dream-opts-list h4 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.dream-opts-list .action {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.dream-opts-list .action a {
  color: var(--Dark-Blue, #086788);
  text-decoration: none;
}

.dream-opts-list .action.disabled {
  color: var(--Grays-Medium, #838b9b);

  user-select: none;
}
.dream-opts-list .action.disabled svg {
  color: var(--Grays-Medium, #838b9b);
}

.dream-opts-list .action svg {
  width: 24px;
  height: 24px;
  color: #086788;
}
