main.dream-loading-main {
  width: 100vw;
  height: 100vh;
  background: #152647;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Spline Sans', sans-serif;

  svg {
    margin: auto;
    position: absolute;
  }

  span {
    color: #fffefa;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: -0.1px;
    opacity: 0.9;
    text-shadow: 0 4px 16px rgba(0, 13, 38, 0.15);
  }
}

@keyframes grow1 {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0.8);
    filter: blur(4px);
  }
  100% {
    transform: scale(1);
    filter: blur(32px);
  }
}

@keyframes grow2 {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(0.6);
    filter: blur(8px);
  }
  100% {
    transform: scale(1);
    filter: blur(32px);
  }
}

@keyframes grow3 {
  0% {
    transform: scale(0);
  }
  75% {
    transform: scale(0.75);
    filter: blur(12px);
  }
  100% {
    transform: scale(1);
    filter: blur(32px);
  }
}

.circ1,
.circ2,
.circ3 {
  transform-origin: 50%;
}

.circ1 {
  animation: grow1 2s linear infinite;
}

.circ2 {
  animation: grow2 2.5s linear infinite;
}

.circ3 {
  animation: grow3 3s linear infinite;
}
