.progress-toast {
  width: 440px;
  padding: 16px;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f7f5e9);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.progress-toast-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.progress-toast .inner-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.progress-toast .action-row {
  color: var(--Light-Blue, #07a0c3);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  line-height: 20px; /* 142.857% */
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.progress-toast .action-row > *:first-child {
  border-left: none;
}

.progress-toast .action-row > * {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  border-left: 1px solid var(--Grays-Light, #c1c5cd);
}

.progress-toast .icon-wrapper svg {
  color: var(--Light-Red);
  width: 32px;
  height: 32px;
}

.progress-toast .right p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.progress-toast .right p b {
  font-weight: 500;
}
