.feed-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  background: #fff;
}

.feed-wrapper.loading {
  border-color: var(--Lisa-Yellow, #f7f5e9);
  background: var(--Lisa-Yellow, #f7f5e9);
  height: 500px;
}

.feed-wrapper.loading * {
  visibility: hidden;
}

.feed-wrapper .feed-head {
  display: flex;
  padding: 12px 12px 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.feed-wrapper .left-side {
  display: flex;
  align-items: center;
  gap: 12px;
}

.feed-wrapper .right-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1 0 0;
}

.feed-wrapper .left-side h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.feed-wrapper .right-side select {
  display: flex;
  width: 170px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.feed-wrapper .toggle-switch {
  display: flex;
  padding: 4px;
  align-items: center;

  border-radius: 8px;
  background: var(--Grays-Lighter, #e6e8eb);
}

.feed-wrapper .toggle-switch .toggle {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.feed-wrapper .toggle-switch .toggle.active {
  border-radius: 4px;
  background: #fff;

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.feed-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.feed-content h6 {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.feed-item {
  display: flex;
  padding: 12px 16px 12px 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.feed-item .main-name .fake-pic,
.feed-item .main-name img,
.feed-item .who-wrapper img,
.feed-item .who-wrapper .fake-pic {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px;
}

.feed-item .main-name .fake-pic {
  background: var(--Grays-Medium, #838b9b);
  color: var(--Plus-White);
  display: flex;
  align-items: center;
  justify-content: center;
}

.feed-item .main-name .fake-pic > svg {
  width: 28px;
  height: 28px;
}

.group-item .main-name,
.feed-item .main-name {
  display: flex;
  width: 254px;
  align-items: center;
  gap: 11px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}
.group-item .tag-section,
.feed-item .tag-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.group-item .tag,
.feed-item .tag {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 3px;

  border-radius: 4px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.group-item .tag.neutral,
.feed-item .tag.neutral {
  background: var(--Grays-Lightest, #f3f3f5);
}
.group-item .tag.positive,
.feed-item .tag.positive {
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.2) 0%,
      rgba(100, 182, 172, 0.2) 100%
    ),
    #fff;
}

.group-item .tag.semi-positive,
.feed-item .tag.semi-positive {
  background: linear-gradient(
      0deg,
      rgba(7, 160, 195, 0.3) 0%,
      rgba(7, 160, 195, 0.3) 100%
    ),
    #fff;
}

.group-item .tag.dream-positive,
.feed-item .tag.dream-positive {
  background: linear-gradient(
      0deg,
      rgba(255, 214, 1, 0.2) 0%,
      rgba(255, 214, 1, 0.2) 100%
    ),
    #fff;
}

.group-item .tag.dream-positive,
.feed-item .tag.dream-positive {
  font-weight: 600;
}

.feed-item .tag b {
  font-weight: 500;
}

.feed-item .main-name a {
  text-decoration: none;
}

.feed-item small {
  flex: 1 0 0;

  color: var(--Grays-Medium, #838b9b);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.load-more-button {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: rgba(7, 160, 195, 0.05);
}

.load-more-button button {
  color: var(--Dark-Blue, #086788);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  background: none;
  border: none;
}

.group-wrapper.expanded .group-item {
  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lighter, #e6e8eb);
}

.group-wrapper .group-item {
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.group-wrapper .feed-item {
  margin-left: 40px;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.group-wrapper.expanded .feed-item:first-child {
  border-top: none;
}

.group-wrapper .group-item {
  display: flex;
  padding: 12px 16px 12px 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.group-wrapper .group-item .main-name {
  display: flex;
  width: 254px;
  align-items: center;
  gap: 11px;
}

.group-wrapper .group-item .expansion-area {
  width: 152px;
}

.group-wrapper .group-item .main-name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.group-wrapper .group-item .main-name span {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 4px;
  text-wrap: nowrap;
  cursor: pointer;
}

.group-wrapper .collected-logos {
  display: flex;
  align-items: center;
}

.group-wrapper .group-item .main-name img {
  border: 1px solid #e6e8eb;
  height: 32px;
  width: 32px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 32px;
  margin-left: -12px;
}

.group-wrapper .group-item .main-name img:first-child {
  margin-left: 0px;
}
.group-wrapper .group-item small {
  flex: 1 1 0;
  color: var(--Grays-Medium, #838b9b);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.feed-wrapper .loading-bar:last-child {
  border-top: 1px solid #e6e8eb;
}

.feed-wrapper .loading-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 16px 12px 24px;
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.feed-wrapper .loading-bar > * {
  color: var(--Light-Blue);
}

.feed-item.upcoming {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.feed-item .scheduled-area {
  display: flex;
  padding: 2px 4px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 200px;

  border-radius: 4px;
  background: rgba(246, 126, 125, 0.2);

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.feed-item .scheduled-area svg {
  color: var(--Light-Red);
  width: 20px;
  height: 20px;
}

.feed-item .who-wrapper {
  display: flex;
  width: 190px;
  align-items: center;
  gap: 11px;
}

.feed-item .who-wrapper.who-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.feed-item .who-wrapper .who-right > *:first-child {
  display: block;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  text-decoration: none;
}

.feed-item .who-wrapper .who-right > *:last-child {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-decoration: none;
}
