.ag-theme-quartz {
  --ag-font-size: 14px;
  --ag-font-family: 'Spline Sans';
  --ag-foreground-color: #152647;
  --ag-control-panel-background-color: #f3f3f5;
  --ag-wrapper-border-radius: 0px;
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #e6e8eb;
  --ag-grid-size: 10px;
  /* and between columns */
  --ag-cell-horizontal-border: solid #e6e8eb;
  --ag-selected-row-background-color: rgba(241, 250, 252, 1);
  --ag-range-selection-border-color: #086788;

  --ag-row-hover-color: rgba(241, 250, 252, 1);

  --ag-header-height: 36px;
  --ag-header-foreground-color: #000d26;
  --ag-header-background-color: #f3f3f5;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: #e6e8eb;

  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 0px;

  --ag-range-selection-background-color: rgb(239, 249, 252, 0.7);
  --ag-range-selection-highlight-color: rgb(239, 249, 252, 1);

  --ag-value-change-value-highlight-background-color: rgb(239, 249, 252, 1);
}

.logo-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo-name .spinner-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-name .spinner-wrapper .spinner-border {
  width: 16px;
  height: 16px;
}

.logo-name img,
.logo-name svg,
.logo-name .org-fake {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.logo-name .org-syncing-spinner {
  width: 24px;
  height: 24px;
}

.logo-name span {
  flex: 1;
}

.logo-name .spinner-wrapper {
  display: flex;
  align-items: center;
}

.value-no-answer {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
}

.new-column-add-clicker {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.add-column-header {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.ag-large-text-input {
  padding: 8px;
}

.ag-large-text {
  border-radius: 8px;
}

.not-found-outer {
  display: flex;
  align-items: center;
  height: 100%;
}

.tether-holder {
  position: relative;
  width: 100%;
  display: block;
}

.tether-dot {
  position: absolute;
  left: -14px;
  top: calc(50% - 4px);
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: var(--Dream-Yellow);
  opacity: 0.5;
}

.research-red-background {
  background: rgba(254, 104, 103, 0.2);
}

.ag-cell-inline-editing {
  border-bottom: 1px solid var(--Grays-Lighter,);
  border: 0px !important;
  box-shadow: none !important;
}

.ag-center-cols-viewport {
  min-height: unset !important;
}

.ag-root-wrapper {
  min-height: 100%;
}
