.match-use-case-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inner-use-case-description p:first-child {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
  margin-bottom: 12px;
}

.highlighted-use-case {
  padding: 16px;
  color: #000;
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  min-height: 10px;
  border-radius: 8px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.match-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.match-list label {
  font-weight: 500;
  font-size: 1.2em;
}

.match-list .form-check {
  display: flex;
  gap: 12px;
}

.match-list .form-check label {
  margin-bottom: 0px;
}

.highlighted-use-case span {
  background: var(--Light-Red, #f67e7d);
  padding: 8px;
  border-radius: 8px;
}

.highlighted-use-case.small {
  font-size: 0.9em;
}

.highlighted-use-case.small span {
  padding: 0px;
  background: none;
  color: var(--royal-purple);
}

.match-use-case-body .empty-state {
  margin-top: 16px;
}

.campaign-step-wrapper .highlighted-use-case {
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}
