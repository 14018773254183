.guidance-approval-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.guidance-approval-modal .top-description {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.guidance-approval-modal .choices-set {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.guidance-approval-modal .choice-header {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  position: relative;
  padding-left: 38px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 8px;
}

.guidance-approval-modal .choice-header span {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

/* Hide the default radio button */
.guidance-approval-modal [type='radio'] {
  display: none;
}

/* Custom Radio Button Styling */
.guidance-approval-modal .choice-header:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.3s ease;
}

.guidance-approval-modal [type='radio']:checked + span:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 8px solid var(--Light-Blue); /* Blue border color */
  background-color: #fff; /* Blue fill color */
  transition: all 0.3s ease;
}

.guidance-approval-modal .choice {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.guidance-approval-modal .choice.inactive {
  opacity: 0.5;
}

.guidance-approval-modal .form-area {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f7f5e9);
}

.guidance-approval-modal .form-area label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.guidance-approval-modal .choice.edit .form-area textarea {
  height: 400px;
}

.guidance-approval-modal .choice.edit textarea {
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.guidance-approval-modal .choice.edit .form-area input {
  display: flex;
  height: 48px;
  padding: 12px 8px 12px 16px;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}
