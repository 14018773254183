.dashboard-opportunities {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.dashboard-opportunities h1 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
}

.opportunities-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.opportunities-section h2 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.opportunities-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.opportunity-card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);

  /* Modal shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.opportunity-card.hot {
  background: linear-gradient(
      180deg,
      rgba(246, 126, 125, 0.15) 0%,
      rgba(255, 214, 1, 0.15) 100%
    ),
    #fffefc;
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.opportunity-card .org-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.opportunity-card .org-logo {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  object-fit: contain;
}

.opportunity-card .org-logo-placeholder {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: var(--Grays-Lighter, #e6e8eb);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-weight: 500;
}

.opportunity-card .org-name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.opportunity-card .opportunity-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.opportunity-card .opportunity-details p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.opportunity-card .opportunity-details p.no-linkedin {
  color: var(--Grays-Dark, #6b7280);
  font-style: italic;
}

.opportunity-card .connection-btn {
  align-self: stretch;

  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: flex-start;
  padding: 0px;
  text-decoration: none;
  text-align: left;
}

.opportunity-card .connection-btn:hover {
  background: transparent !important;
}

.opportunities-loading {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  padding: 16px;
}

.warmth-factors {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.warmth-factors b {
  font-weight: 500;
  font-size: 13px;
}

.warmth-factors .warmth-factor {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  opacity: 0.8;
}

.opportunity-card .phone-number {
  display: flex;
  gap: 8px;
  align-items: center;
}

.opportunity-card .phone-number a {
  vertical-align: center;
  display: flex;
  align-items: center;
}

.completed-time {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  margin-top: 2px;
}

.opportunities-section-title {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.activity-history-button {
  display: flex;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid rgba(92, 104, 128, 0.25);
  background: white;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
}

.opportunity-card .top-wrapper {
  display: flex;
  padding-bottom: 4px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
