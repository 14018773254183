.page-inner-wrapper.analyst-index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  align-self: stretch;
  height: 100vh;
}

.page-inner-wrapper.analyst-index .left-tabs-wrapper {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.dream-left-tabs {
  display: flex;
  width: 208px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
  border-bottom: none;
  border-top: none;
}

.page-inner-wrapper.analyst-index .dream-left-tabs {
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lightest, #f3f3f5);
  padding-top: 24px;
}

.page-inner-wrapper.analyst-index .tab-content {
  padding-top: 24px;
}

.dream-left-tabs li.nav-item {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: none;
}

.dream-left-tabs li.nav-item .nav-link {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  align-self: stretch;
  border-radius: 0px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.dream-left-tabs li.nav-item .nav-link.active {
  border-right: 2px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.left-tabs-wrapper .tab-content > *,
.left-tabs-wrapper .tab-content {
  flex: 1;
}

.left-tabs-wrapper .tab-content {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.left-tabs-wrapper .tab-content > *.active {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  width: 100%;
}

.analyst-index .knowledge-base {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}
.analyst-index .knowledge-base .ask-question-group {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.analyst-index .knowledge-base .ask-question-group label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.analyst-index .knowledge-base h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.analyst-index .knowledge-base .ask-question-group button {
  margin-top: 4px;
}

.analyst-index .found-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}

.search-input-wrapper input {
  width: 300px;
}

.search-input-wrapper label {
  margin-bottom: 0px;
}

.page-inner-wrapper.analyst-view {
  width: 100%;
}

.page-inner-wrapper.analyst-view .title-and-text h1 {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.page-inner-wrapper.analyst-view .title-and-text h1 img,
.page-inner-wrapper.analyst-view .title-and-text h1 svg {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.analyst-view .main-area {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  padding: 24px;
}

.analyst-view .about-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.analyst-view h2 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.analyst-view .tab-content {
  width: 100%;
}

.analyst-view .trigger-area {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.analyst-view .trigger-area .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.analyst-view .trigger-area .header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.research-point-side-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.research-point-side-wrapper .top-definition {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.cost-area {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.cost-area .area {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 0 0;
}

.cost-area .area p.label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.cost-area .area p.value {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}

.research-point-side-wrapper .detail-area {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
  align-self: stretch;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  border-top: 0px;
  background: #fff;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.research-point-side-wrapper .detail-area .title {
  font-size: 16px;
  font-weight: 500;
}

.research-point-side-wrapper ul {
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.research-point-side-wrapper .slider-wrapper {
  width: 100%;
}

.dream-side-modal-form .described textarea {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dream-side-modal-form .textarea-hanger {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  border-top: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.research-point-side-wrapper .dream-side-modal-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.research-point-side-wrapper .slider-wrapper {
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Grays-Lightest, #f3f3f5);
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  align-self: stretch;
}

.research-point-side-wrapper .slider-wrapper b {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.research-point-side-wrapper .slider-wrapper span {
  padding-left: 0px;
  padding-right: 0px;
}

.analyst-view .datapoints-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.datapoint {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.datapoint .label {
  display: flex;
  width: 192px;
  padding: 8px 16px;
  align-items: flex-start;
  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  align-self: stretch;
}

.datapoint .value-wrapper {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}

.create-new-datapoint {
  margin-top: 24px;
}

.create-new-datapoint button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.datapoint .value-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.datapoint .value-wrapper .value {
  margin-right: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.datapoint .value-wrapper .refresh-icon {
  visibility: hidden;
  cursor: pointer;
}

.datapoint .value-wrapper:hover .refresh-icon {
  visibility: visible;
}

.datapoint .error-indicator {
  background: var(--Dream-Yellow);
  width: 8px;
  display: block;
  border-radius: 36px;
  height: 8px;
}

.datapoint .error-value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.datapoint .queued-value {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.datapoint .queued-value {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.datapoint .spinner-border {
  color: var(--Dark-Blue);
}

.analyst .campaign-triggers {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.analyst .campaign-triggers .campaign-trigger {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Plus-White, #fffefa);
}

.analyst .campaign-triggers p.time {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaign-trigger .campaign-trigger-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
}

.campaign-trigger .title {
  font-weight: 500;
}

.campaign-trigger button {
  width: 140px;
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.campaign-trigger .definition {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.campaign-trigger .answer-area {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.campaign-trigger .answer-area p.value {
  flex: 1;
}
.campaign-trigger .answer-area p.label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 120% */
  width: 80px;
  margin-top: 3px;
}

.campaign-trigger .answer-area b {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.knowledge-base .feed-profile-image {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.knowledge-base .fake-pic svg {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.knowledge-base-for-company .knowledge-area {
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}
.knowledge-base-for-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.knowledge-base-for-company .knowledge-area li,
.knowledge-base-for-company .knowledge-area p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.knowledge-base-for-company .knowledge-area h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.analyst-answer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
}

.analyst-answer .header-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.header-area .breadcrumbs {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  cursor: pointer;
}

.analyst-answer .header-area .question-view {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Lisa-Yellow, #f0eddc);

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.analyst-answer .section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.analyst-answer .section p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.analyst-answer .section h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
}

.analyst-answer .section .answer-area {
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.analyst-answer .section p.description {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.analyst-answer .sample {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.analyst-answer .sample hr {
  background: var(--Grays-Light, #c1c5cd);
  height: 1px;
  width: 100%;
  margin: 0px !important;
  opacity: 1 !important;
  border: none;
}

.analyst-answer .button-wrapper button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.research-points-used {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.research-points-used p:first-child {
  font-weight: 500;
}

.analyst-index .buttons-area button {
  display: flex;
  gap: 12px;
  align-items: center;
}

.analyst-autopilot-confirm {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.research-point-solo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.research-point-solo-wrapper .header {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.research-point-solo-wrapper .header .left-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.research-point-solo-wrapper .header .buttons-area button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.research-point-solo-wrapper .campaigns-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.research-point-solo-wrapper .campaign-trigger {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Plus-White, #fffefa);
}

.research-point-solo-wrapper .campaign-trigger p:first-of-type {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.research-point-solo-wrapper .campaign-trigger p:first-of-type b {
  font-weight: 500;
}

.research-point-solo-wrapper .campaign-trigger .info-area {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
}

.research-point-solo-wrapper .campaign-trigger .info-area .set {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 0 0;
}

.research-point-solo-wrapper .campaign-trigger .info-area .set p.label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.research-point-solo-wrapper .campaign-trigger .info-area .set p.value {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
}

.research-point-solo-wrapper .datapoints-wrapper {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
}

.research-point-solo-wrapper .datapoints-wrapper .datapoint-pill {
  border-radius: 24px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f0eddc);
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.research-point-solo-wrapper .datapoints-outer-wrapper {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.research-point-solo-wrapper h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.research-point-solo-wrapper
  .campaigns-area.datapoint
  .empty-state
  > svg:first-child {
  width: 40px;
  height: 40px;
}

p.intro-description {
  font-weight: 400;
}

.research-point-side-wrapper.dream-side-modal-form {
  margin-top: 24px;
}

.research-point-side-wrapper .setup-prompts-button {
  width: 100%;
}

.research-point-side-wrapper .setup-prompts-button svg {
  color: var(--Light-Red);
}

.template-selector {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
  width: 100%;
  align-items: stretch;
}

.template-selector > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.template-selector h5 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.template-selector .template-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.template-selector .template-list .template {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  cursor: pointer;
}

.template-selector .template-list .template svg {
  color: var(--Light-Red);
}

.rpm-switch-group {
}

.rpm-zombie-group .side-by-side {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rpm-zombie-group select {
  width: 150px;
}

.rpm-zombie-group .side-by-side > *:last-child {
  flex: 1;
}
