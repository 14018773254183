.advanced-funnel-create {
  width: 100%;
}

.advanced-lower-area {
  display: flex;
  padding: 24px 32px 64px 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.advanced-lower-area .lower-area-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.advanced-lower-area .lower-area-header h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.advanced-lower-area .lower-area-header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.advanced-lower-area .lower-area-form {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  border-radius: 24px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: rgba(243, 243, 245, 0.5);
}

.advanced-lower-area .docs-warning-alert {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Performa-Black, #152647);
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.advanced-lower-area .docs-warning-alert svg {
  color: var(--Light-Grey);
}

.advanced-lower-area .docs-warning-alert a {
  color: var(--Light-Blue);
  text-decoration: none;
}

.advanced-form-wrapper .inner-opt-group,
.advanced-form-wrapper > .form-group {
  width: 768px;
}

.advanced-form-wrapper .choices {
  display: flex;
  align-items: stretch;
  gap: 12px;
  align-self: stretch;
  margin-top: 12px;
}

.advanced-form-wrapper {
  display: flex;
  padding: 24px;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.advanced-form-wrapper .choices {
  flex-wrap: wrap;
}

.advanced-form-wrapper .choices .choice {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  width: 248px;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  cursor: pointer;
}

.advanced-form-wrapper .choices .choice svg {
  height: 24px;
  flex-shrink: 0;
  align-self: stretch;
  color: var(--Light-Blue);
}

.advanced-form-wrapper .choices .choice span {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.add-agent-filter {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  width: 100%;
}

.add-agent-filter svg {
  color: var(--Light-Blue);
}

.advanced-form-wrapper .choices .choice.active {
  border-radius: 12px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.advanced-form-wrapper .choices .choice.active svg {
  color: var(--Wallstreet-Black, #000d26);
}

.advanced-form-wrapper .stage-additional-options .options-holder {
  margin-top: 0px;
}

.funnel-icon-wrapper {
  color: var(--Light-Blue);
}

.advanced-funnel-create .filter-choice {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;

  cursor: pointer;
}

.advanced-funnel-create .filter-choice svg {
  color: var(--Light-Blue);
  width: 24px;
  height: 24px;
}

.filter-choice .choice-left {
  display: flex;
  width: 385px;
  align-items: center;
  gap: 12px;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.filter-choice .links {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}

.filter-choice .links .edit {
  color: var(--Dark-Blue, #086788);
}

.filter-choice .links .remove {
  color: var(--Dark-Red, #931f1d);
}

.advanced-funnel-create .filters-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

button.create-list-builder {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.create-list-builder-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 768px;
}

.advanced-funnel-create .errors {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Light-Red);
  background: linear-gradient(
      0deg,
      rgba(195, 7, 7, 0.1) 0%,
      rgba(195, 7, 7, 0.1) 100%
    ),
    #fff;

  width: 768px;
}

.advanced-funnel-create .errors-wrapper {
  display: flex;
  justify-content: center;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.advanced-form-wrapper input,
.advanced-form-wrapper textarea,
.advanced-form-wrapper select {
  border: 1px solid var(--Grays-Light, #c1c5cd);
}
