.campaign-edit-messaging {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.edit-messaging-lower-area {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background: var(--Plus-White, #fffefc);
}

.edit-messaging-lower-area > * {
  flex: 1;
  overflow: hidden;
}

.edit-messaging-lower-area .name-wrapper {
  display: flex;
  width: 232px;
  align-items: center;
  gap: 12px;
  font-weight: 500;
}
.edit-messaging-lower-area .name-wrapper > *:first-child {
  white-space: auto !important;
}

.edit-messaging-lower-area .name-wrapper span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-list-wrapper {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.message-list-wrapper .rdt_TableCell {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.message-list-wrapper .dream-table-wrapper {
  width: 100%;
}

.message-list-wrapper .tag {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Grays-Lighter, #e6e8eb);
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.message-list-wrapper .tag.positive {
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.2) 0%,
      rgba(100, 182, 172, 0.2) 100%
    ),
    #fff;
}

.message-details-wrapper .heading {
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.message-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.message-details-wrapper .heading .set p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.message-details-wrapper .heading .set p:last-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.message-details-wrapper .message-content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.message-details-wrapper .subject-and-content {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.message-details-wrapper .send-button-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.message-details-wrapper .send-button-wrapper button {
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.message-details-wrapper .send-button-wrapper button svg {
  color: var(--Dream-Yellow);
}

.message-details-wrapper .make-changes-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
}

.message-details-wrapper .make-changes-wrapper button {
  width: 100%;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.message-details-wrapper .dream-side-modal-form .actions-row {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.message-details-wrapper .dream-side-modal-form .actions-row button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaign-edit-messaging-error-wrapper {
  margin-bottom: 16px;
}

.message-list-wrapper .tag.warning {
  border-radius: 4px;
  background: rgba(255, 214, 1, 0.1);
}
