.campaigns-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.campaigns-list h1 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
}

.campaigns-list .campaigns-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.campaigns-list h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  cursor: pointer;
  user-select: none;
}

.campaigns-list h2 span {
  font-weight: 400;
}

.campaigns-list h2 .caret-icon {
  width: 16px;
  height: 16px;
  opacity: 0.5;
}

.campaigns-list .campaign-card {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.campaigns-list .campaign-card h3 {
  display: flex;
  padding: 12px 12px 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.campaigns-list .campaign-card .lower-area {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: rgba(243, 243, 245, 0.3);
}

.campaigns-list .campaign-card .stats {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.campaigns-list .campaign-card .stat {
  display: flex;
  padding-left: 8px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  border-left: 1px solid var(--Grays-Light, #c1c5cd);
}

.campaigns-list .campaign-card .stat p.value {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.campaigns-list .campaign-card .health-item p.value {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  align-self: flex-start;
}

.campaigns-list .campaign-card .health-status {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  flex: 1 0 0;
  width: 100%;
  justify-content: space-between;
}

.campaigns-list .campaign-card .health-item {
  display: flex;
  height: 40px;
  padding-left: 8px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  border-left: 1px solid var(--Grays-Light, #c1c5cd);
}

.campaigns-list .campaign-card label {
  color: var(--Grays-Dark, #5c6880);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.campaigns-list .campaign-card p.value {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.campaigns-list .campaign-card.draft {
  display: flex;
  padding: 12px;
  gap: 8px;
  align-self: stretch;
  flex-direction: row;
}

.campaigns-list .campaign-card.draft .draft-icon {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  border-radius: 24px;
  background: var(--Dream-Yellow, #ffd601);
}

.campaigns-list .campaign-card.draft .draft-icon svg {
  width: 20px;
  height: 20px;
  color: var(--Wallstreet-Black);
}

.campaigns-list .campaign-card.draft .draft-content {
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 1;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.campaigns-list .campaign-card.draft p {
  color: var(--Grays-Dark, #5c6880);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.campaigns-list .queue-date {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.campaigns-list .loading {
  color: var(--Grays-Dark, #5c6880);
  font-family: 'Spline Sans';
  font-size: 14px;
  padding: 16px;
}

.campaign-card .health-item.red {
  border-left: 1px solid var(--Light-Red, #f67e7d);
}

.campaign-card .health-item.red p.value {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(246, 126, 125, 0.25);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-card .health-item.amber {
  border-left: 1px solid var(--Dream-Yellow, #ffd601);
}

.campaign-card .health-item.amber p.value {
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(255, 214, 1, 0.25);

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-card.health-red {
  border: 1px solid var(--Light-Red, #f67e7d);
}
.campaign-card.health-red h3 {
  background: rgba(246, 126, 125, 0.05);
}

.campaign-card.health-amber {
  border: 1px solid var(--Dream-Yellow, #ffd601);
}
.campaign-card.health-amber h3 {
  background: rgba(255, 214, 1, 0.05);
}

.campaign-card .prospecting-health {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  cursor: pointer;
  user-select: none;
}

.campaign-card .prospecting-health.red {
  border-radius: 4px;
  background: rgba(246, 126, 125, 0.2);
}

.campaign-card .prospecting-health.green {
  border-radius: 4px;
  background: var(--Grays-Lighter, #e6e8eb);
}

.campaign-card .prospecting-health.green svg {
  color: #07a0c3;
}

.capacity-warning {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.capacity-warning.red {
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.05) 0%,
      rgba(246, 126, 125, 0.05) 100%
    ),
    #fff;
}

.capacity-warning.amber {
  background: rgba(255, 214, 1, 0.1);
}

.capacity-warning svg {
  width: 24px;
  height: 24px;
}

.capacity-warning .warning-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.capacity-warning h3 {
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.capacity-warning p {
  align-self: stretch;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.capacity-warning .warning-actions a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}

.capacity-warning .warning-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.capacity-warning .action-link:hover {
  text-decoration: underline;
}
