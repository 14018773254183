.campaigns button svg {
  color: var(--Dream-Yellow);
}

.campaigns-index {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
}

.campaigns-index-lower-area {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.getting-started-campaigns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.getting-started-campaigns .header {
  display: flex;
  width: 412px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.getting-started-campaigns h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.getting-started-campaigns h4 + p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.getting-started-campaigns .guidance-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.getting-started-campaigns .guidance-wrapper .guidance {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 16px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.getting-started-campaigns .guidance-wrapper .guidance .icon-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance
  .icon-holder
  .done-wrapper
  svg {
  width: 32px;
  height: 32px;
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance
  .icon-holder
  .icon-wrapper {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.active
  .icon-holder
  .icon-wrapper {
  border-radius: 4px;
  background: var(--Performa-Black, #152647);
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance
  .icon-holder
  .icon-wrapper {
  border-radius: 4px;
  background: var(--Grays-Lightest, #f3f3f5);
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.target
  .icon-holder
  .icon-wrapper
  svg {
  width: 40px;
  height: 40px;
  color: var(--Light-Blue);
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.account
  .icon-holder
  .icon-wrapper
  svg {
  width: 40px;
  height: 40px;
  color: var(--Dark-Blue);
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.profiles
  .icon-holder
  .icon-wrapper
  svg {
  width: 40px;
  height: 40px;
  color: var(--Light-Green);
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.active
  .icon-holder
  .icon-wrapper
  svg {
  width: 40px;
  height: 40px;
  color: #fff;
}

.guidance .icon-holder + div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.guidance h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.guidance .icon-holder + div p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.getting-started-campaigns .guidance-wrapper .guidance.active {
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.active
  .icon-wrapper
  svg {
  color: #fff !important;
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.active
  .icon-wrapper
  svg
  path {
  color: #fff !important;
  fill: currentColor !important;
}

.getting-started-campaigns
  .guidance-wrapper
  .guidance.active
  svg
  linearGradient {
  display: none;
}

.guidance .do-wrapper a {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: #fff;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}

.guidance.active .do-wrapper a {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);

  color: #fff;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}

.guidance .done-wrapper svg {
  width: 20px;
  height: 20px;
}

.no-campaigns-view .main-page-empty-area {
  padding: 0px;
}

.no-campaigns-view .main-page-empty-area > svg {
  width: 48px;
  height: 48px;
  color: var(--Wallstreet-Black);
}

.no-campaigns-view .empty-state button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaigns-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.campaigns-index .campaigns-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.campaigns-index .campaigns-list h4 {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.campaigns-index .campaigns-list h4 span {
  font-weight: 400;
}

.campaigns-index .pseudo-table {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.campaigns-index .body-row:hover {
  background: var(--Very-Light-Grey);
}

.campaigns-index .header-row {
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.campaigns-index .header-row,
.campaigns-index .body-row {
  display: flex;
  padding: 12px 24px 12px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.campaigns-index .cell.campaign-name {
  width: 240px;
}

.campaigns-index .cell.campaign-name a {
  text-decoration: none;
}

.campaigns-index .cell.campaign-name a:hover {
  text-decoration: underline;
}

.campaigns-index .remaining-cells {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.campaigns-index .remaining-cells > * {
  flex: 1;
}

.campaigns-index .remaining-cells > *:last-child {
  text-align: right;
}

.campaigns-index .header-row .cell {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaigns-index .body {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.campaigns-index .body-row .cell {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.campaigns-index .body-row {
  display: flex;
  height: 58px;
  padding: 20px 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.campaigns-index .tag {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
}

.campaigns-index .tag.alert {
  background: var(--Dream-Yellow, #ffd601);
}

.campaigns-index .tag.neutral {
  background: var(--Grays-Lightest, #f3f3f5);
}

.campaigns-index .cell:last-of-type {
  justify-content: flex-end;
  display: flex;
  width: 180px;
  align-items: center;
  gap: 12px;
}

.campaigns-index .cell {
  display: flex;
}

.dropdown-toggle::after {
  display: none;
}

.campaign-wizard,
.campaign-view {
  width: 100%;
}

.campaign-wizard .page-header-area,
.campaign-view .page-header-area {
  border-bottom: none;
}

.campaign-view-tabs,
.campaign-wizard-tabs {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.campaign-wizard-tabs .settings-tab.active,
.campaign-view-tabs .settings-tab.active {
  border-radius: 4px;
  background: var(--Dream-Yellow, #ffd601);
  color: var(--Wallstreet-Black);
}

.campaign-wizard-tabs .settings-tab,
.campaign-view-tabs .settings-tab {
  display: flex;
  padding: 8px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.campaign-wizard-tabs .main-tabs,
.campaign-view-tabs .main-tabs {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-wizard-tabs .nav-link,
.campaign-view-tabs .nav-link {
  padding: 0px;
}

.campaign-wizard-tabs .tab-immitate:last-child > svg,
.campaign-view-tabs .tab-immitate:last-child > svg {
  display: none;
}

.campaign-wizard-tabs .active.tab-inner,
.campaign-view-tabs .active.tab-inner {
  display: flex;
  padding: 8px 24px 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.tab-immitate {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.tab-immitate > svg {
  color: #838b9b !important;
}

.campaign-wizard-tabs .tab-inner,
.campaign-view-tabs .tab-inner {
  display: flex;
  padding: 8px 24px 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaign-wizard-tabs .step,
.campaign-view-tabs .step {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.campaign-view-tabs .step-number-sig,
.campaign-wizard-tabs .step-number-sig {
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.campaign-view-tabs .completed .step-number-sig,
.campaign-wizard-tabs .completed .step-number-sig {
  background: var(--Light-Green);
  border-radius: 24px;
  color: white;
}

.campaign-view-tabs .completed .step-number-sig svg,
.campaign-wizard-tabs .completed .step-number-sig svg {
  color: white;
}

.campaign-view-tabs .active.completed .step-number-sig,
.campaign-wizard-tabs .active.completed .step-number-sig {
  border-radius: 24px;
  background: var(--Performa-Black, #152647);
  color: white;
}

.campaign-view-tabs .disabled .step-number-sig,
.campaign-wizard-tabs .disabled .step-number-sig {
  border-radius: 24px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--Plus-White, #fffefa);
  color: var(--Grays-Medium, #838b9b);
}

.campaign-view-tabs .disabled,
.campaign-wizard-tabs .disabled {
  color: var(--Grays-Medium, #838b9b);
}

.campaign-wizard,
.campaign-view {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
}

.campaign-view .tab-content,
.campaign-wizard .tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 32px;
  align-self: stretch;
  overflow: hidden;
  background: var(--Plus-White, #fffefc);
}

.campaign-step-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}

.campaign-step-wrapper .header-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.campaign-step-wrapper .header-area h3 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.campaign-step-wrapper .buttons-area {
  display: flex;
  align-items: center;
  gap: 16px;
}
.campaign-step-wrapper .header-area button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.leading-description {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.tab-content .step-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.step-content > .campaign-step-wrapper {
  align-self: center;
  width: 100%;
}

.campaign-purpose h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px !important;
}

.campaign-purpose .editable-fields {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.companies-step {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}
.campaign-triggers-area .empty-state > .red-icon svg,
.companies-area .empty-state > svg {
  width: 40px;
  height: 40px;
}

.campaign-triggers-area .empty-state > p,
.companies-area .empty-state > p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.campaign-triggers-area .empty-state > button,
.companies-area .empty-state > button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaigns .step-form-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.campaigns .step-form-header h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.campaigns .step-form-header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 160% */
}

.edit-companies-modal label {
  font-weight: 500;
  margin-bottom: 12px;
}

.items-to-choose {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.items-to-choose .item {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefa);
}

.items-to-choose h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.items-to-choose .item.sexy-white {
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.items-to-choose .item.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.create-new-button {
  display: flex;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: 20px;
}

.list-builders-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.list-builders-wrapper .button-area button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Plus-White, #fffefc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.list-builders-wrapper .button-area button svg {
  color: var(--Wallstreet-Black);
}

.lists-overview {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.lists-overview .funnel {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefa);
}

.lists-overview .funnel.fake-funnel {
  min-height: 58px;
}

.lists-overview .funnel p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.lists-overview .funnel p:last-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.datapoints-list-wrapper > p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.datapoints-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.datapoints-selection label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.datapoints-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.datapoint-pills {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  user-select: none;
}

.datapoint-pills .pill.active {
  border-radius: 24px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.datapoint-pills .pill {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  border-radius: 24px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.datapoints-criteria {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.datapoints-criteria .criteria-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
}

.datapoints-criteria .criteria-wrapper label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: flex;
  justify-content: space-between;
}

.datapoints-criteria .full-line {
  flex: 1;
  text-align: right;
}

.datapoints-criteria .full-line a {
  color: var(--Dark-Blue);
  font-weight: 400;
  text-decoration: none;
}

.campaign-step-wrapper .campaign-triggers-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.list-builders-wrapper .filter-footer {
  align-self: stretch;
}

.list-builders-wrapper .filter-footer span {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.list-builders-wrapper .dp-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -1px;
  margin-top: 8px;
  align-self: stretch;
}

.list-builders-wrapper .dp-list .dp {
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-triggers-edit-modal > label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-triggers-edit-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.campaign-triggers-edit-modal .empty-state button {
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.campaign-triggers-edit-modal .cts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.campaign-triggers-edit-modal .cts .ct {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.campaign-triggers-edit-modal .cts .ct.active {
  border-radius: 12px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.campaign-triggers-edit-modal .cts .ct.active .name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaign-triggers-edit-modal .cts .ct .name {
  align-self: stretch;

  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 3px;
}

.campaign-triggers-edit-modal .cts .ct .definition {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  margin-bottom: 16px;
}
.campaign-triggers-edit-modal .cts .ct .left {
  width: 343px;
}

.campaign-triggers-edit-modal .cts .ct .left p:last-of-type {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 120% */
}

.companies-step .campaign-triggers-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.companies-step .triggers-rule {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.companies-step .triggers-check {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.companies-step .triggers-list {
  align-self: stretch;
}

.companies-step .triggers-list .campaign-trigger {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefa);
}

.companies-step .triggers-list .campaign-trigger .left {
  flex: 1;
}

.companies-step .triggers-list .campaign-trigger .right {
  width: 284px;
  flex-shrink: 0;

  color: var(--Wallstreet-Black, #000d26);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.companies-step .triggers-list .trigger-name {
  font-weight: 500;
  font-size: 14px;
}

.companies-step .triggers-list .trigger-definition {
  font-size: 16px;
}

.companies-step .campaign-triggers-wrapper button {
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.campaign-triggers-edit-modal .cts .ct:not(.active) .left p:last-of-type b {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.campaign-view .autopilot button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.ocl-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  background: var(--Grays-Lightest, #f3f3f5);
}

.ocl-wrapper .embedded-list-header {
  display: flex;
  height: 72px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.ocl-wrapper .dream-table-wrapper .rdt_TableHeadRow {
  background: var(--Grays-Lightest, #f3f3f5);
}

.ocl-wrapper .dream-table-wrapper {
  flex: 1;
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: scroll;
}

.ocl-wrapper h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.ocl-wrapper h5 b {
  font-weight: 500;
}

.ocl-wrapper .alert-button-wrapper {
  display: flex;
  gap: 4px;
}

.ocl-wrapper .alert-button-wrapper span {
  text-decoration: underline;
  cursor: pointer;
}

.ocl-wrapper .set h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.ocl-wrapper .set p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.prospects-list-wrapper .dream-table-wrapper svg,
.ocl-wrapper .dream-table-wrapper svg {
  width: 17.5px;
  height: 20px;
  color: var(--Dark-Blue);
}

.prospects-list-wrapper .dream-table-wrapper .rdt_TableCell,
.ocl-wrapper .dream-table-wrapper .rdt_TableCell {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.text-wrap {
  padding: 16px 0px;
}

.prospects-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.prospects-list-wrapper .set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.prospects-list-wrapper .set h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.prospects-list-wrapper .set p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.with-missing {
  display: flex;
  flex-direction: column;
}

.title-number {
  padding: 2px 8px;
  border-radius: 24px;
  color: #000;
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}

.title-number.active {
  background: var(--Light-Red, #f67e7d);
}
.title-number.inactive {
  background: var(--Very-Light-Grey);
}

.has-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.campaign-overview .overview-lower-activity {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}
.campaign-overview .overview-lower-activity h5 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.overview-lower-activity .set {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.overview-lower-activity .activity-notifications-wrapper {
  max-height: 500px;
  overflow-y: auto;
}

.main-campaign-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.now-and-next-wrapper {
  display: flex;
  align-items: stretch;
  align-self: stretch;
}

.now-and-next-wrapper svg {
  color: var(--Light-Blue);
}

.now-and-next svg {
  width: 24px;
  height: 24px;
}

.now-and-next-wrapper .now-and-next {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;

  border-radius: 8px;
  background: #fff;
}

.now-and-next-wrapper .now {
  border: 1px solid var(--Light-Blue, #07a0c3);
}

.now-and-next-wrapper .next {
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.now-to-next-icon-holder {
  margin-right: 8px;
  display: flex;
  align-items: center;
  margin-left: -1px;
}

.now-and-next .header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.now-and-next p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.now-and-next.next svg {
  color: var(--Grays-Medium, #838b9b);
}

.now-and-next.next h6 {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.now-and-next.now h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.no-campaigns-view .header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.no-campaigns-view .empty-state {
  border: none;
}

.campaign-view .page-header-area h3,
.campaign-wizard .page-header-area h3 {
  font-weight: 400;
}

.campaign-view .page-header-area h3 b,
.campaign-wizard .page-header-area h3 b {
  font-weight: 500;
}

.column-action {
  display: flex;
  gap: 12px;
}
.column-action button {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table-delete-button:hover {
  color: var(--Dark-Red);
  border-color: var(--Dark-Red) !important;
}

.table-delete-button {
  border: 1px solid var(--Dark-Red);
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  color: var(--Dark-Red) !important;
  border-radius: 6px;
}

.table-delete-button svg {
  color: var(--Dark-Red) !important;
  height: 20px;
}

.table-delete-button,
.column-action button {
  background: none;
}

.column-action button:hover {
  background: none;
  color: var(--Wallstreet-Black);
}

.ocl-wrapper .rdt_Table img {
  width: 32px;
  height: 32px;
  border-radius: 26px;
}

.ocl-wrapper .rdt_Table .fake-pic {
  width: 32px;
  height: 32px;
}

.ocl-wrapper .rdt_Table .fake-pic svg {
  width: 16px;
  height: 16px;
}

.campaign-wizard .initial-loading {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.embedded-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
}

.embedded-list-header .left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.embedded-list-header .left button {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.embedded-list-header .left button:hover {
  color: var(--Wallstreet-Black, #000d26);
  background: transparent;
}

.embedded-list-header .right button {
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.middle-loading-view {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 48px;
}

.big-start-campaign-button {
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
}

.start-campaign {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.start-campaign .step-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.start-campaign .step-wrapper h6 {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: var(--Pomona-Black, #5c6880);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.start-campaign .step-wrapper .heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.start-campaign .step-wrapper .heading h5 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.start-campaign .step-wrapper .heading p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.start-campaign button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-radius: 8px;
  height: 45px;
}

.start-campaign button svg {
  width: 24px;
  height: 24px;
}

.start-campaign b {
  font-weight: 500;
}

.start-campaign *.transparent {
  opacity: 0.5;
}

.start-campaign .settings-holder > * {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.start-campaign .settings-holder .form-check-label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.start-campaign .setting-group .options-wrapper {
  margin-top: 16px;
}

.start-campaign .done-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--Light-Green, #64b6ac);
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.start-campaign .done-button svg {
  width: 24px;
  height: 24px;
  color: var(--Light-Green);
}

.prospect-list-page .page-inner-wrapper .wrapper-of-hope-it-doesnt-break {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.prospect-list-page
  .page-inner-wrapper
  .wrapper-of-hope-it-doesnt-break
  .prospects-lower-area {
  padding: 0px;
}

.campaign-edit-general {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.campaign-edit-general .lower-area {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
  background: var(--Plus-White, #fffefc);
  flex: 1;
}

.fake-placeholder {
  width: 100px;
  height: 16px;
  border-radius: 12px;
  background: var(--Lighter-Grey);
}

.fake-placeholder.reason {
  width: 300px;
}

.ocl-wrapper .alert-wrapper {
  margin-bottom: 6px;
  margin-top: 6px;
  transition:
    opacity 0.5s ease-in-out,
    height 0.5s ease-in-out,
    margin 0.5s ease-in-out;
}

.ocl-wrapper .alert-wrapper.hidden {
  opacity: 0;
  height: 0px;
  margin: 0px;
  margin-bottom: -16px;
}
.ocl-wrapper .alert-wrapper.visible {
  opacity: 1;
  height: 38px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.fake-select-loading {
  height: 48px;
  padding: 12px 8px 12px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  display: flex;
}

.fake-select-loading .spinner-border {
  color: var(--Light-Blue);
}

.body-row .tag.positive {
  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.2) 0%,
      rgba(100, 182, 172, 0.2) 100%
    ),
    #fff;
}

.campaigns-index .page-header-area {
  min-height: 80px;
}

.ss-outer .big-add-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  width: 100%;
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ocl-empty-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.ocl-empty {
  display: flex;
  width: 439px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.ocl-empty p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ocl-empty button {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin-top: 12px;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
