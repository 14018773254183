.admin-dashboard {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    color: var(--Performa-Black, #152647);
    font-family: "Spline Sans";
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    color: var(--Grays-Dark);
  }

  &.error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    color: var(--Light-Red);
  }

  .company-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    
    .company-card {
      background: white;
      border-radius: 12px;
      border: 1px solid var(--Grays-Lighter, #E6E8EB);
      padding: 24px;
      
      h2 {
        color: var(--Performa-Black, #152647);
        font-family: "Spline Sans";
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .stat {
          display: flex;
          flex-direction: column;
          gap: 4px;

          label {
            color: var(--Grays-Dark);
            font-family: "Spline Sans";
            font-size: 13px;
            font-weight: 400;
          }

          .value-container {
            display: flex;
            align-items: baseline;
            gap: 8px;

            value {
              color: var(--Performa-Black, #152647);
              font-family: "Spline Sans";
              font-size: 24px;
              font-weight: 500;
            }

            .percentage-change {
              font-family: "Spline Sans";
              font-size: 13px;
              font-weight: 500;
              padding: 2px 6px;
              border-radius: 4px;

              &.positive {
                color: var(--Success-Green, #0A8043);
                background: rgba(10, 128, 67, 0.1);
              }

              &.negative {
                color: var(--Light-Red, #F67E7D);
                background: rgba(246, 126, 125, 0.1);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .company-grid {
      grid-template-columns: 1fr;
    }
  }
}
