.fake-banner-box {
  display: flex;
  height: 146px;
  align-items: center;
  gap: -1px;
  align-self: stretch;

  border-radius: 16px;
  background: var(--Lisa-Yellow, #f7f5e9);
}
.campaign-progress {
  display: flex;
  align-items: center;
  gap: -1px;

  height: 146px;
  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
}

.campaign-progress .banner-box:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-left: 0px;
}

.campaign-progress .banner-box:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.campaign-progress .banner-box {
  display: flex;
  padding: 16px 16px 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-left: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lightest, #f3f3f5);
}

.campaign-progress .banner-box.active {
  background: rgba(7, 160, 195, 0.1);
}

.campaign-progress .banner-box.done {
  background: #fff;
}

.campaign-progress .active-tag .spinner-border {
  color: var(--Light-Blue);
  width: 19px;
  height: 19px;
}

.campaign-progress .active-tag .done-tag {
  display: flex;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.4) 0%,
      rgba(100, 182, 172, 0.4) 100%
    ),
    #f3f3f5;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.campaign-progress .banner-box.warning {
  background: rgba(255, 214, 1, 0.1);
}

.campaign-progress .banner-box.danger {
  background: rgba(246, 126, 125, 0.1);
}

.campaign-progress .active-tag .waiting-tag {
  display: flex;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: linear-gradient(0deg, #ffd601 0%, #ffd601 100%), #f3f3f5;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.campaign-progress .main-body h6 {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.campaign-progress .main-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.campaign-progress .main-body p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  text-align: center;
}

.campaign-progress .main-body p a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  text-decoration: none;
}

.campaign-progress .active-tag svg {
  width: 24px;
  height: 24px;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

.count svg {
  color: var(--Pomona-Black, #5c6880);
  width: 24px;
  height: 24px;
}

.banner-box.inactive {
  background: var(--Grays-Lightest, #f3f3f5);
}

.count .red {
  font-size: 18px;
  color: var(--Dark-Red);
}
