.inbox-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  background: #fff;
}

.inbox-header {
  display: flex;
  padding: 12px 12px 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.inbox-header h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.inbox-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.inbox-filters .checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.inbox-stats {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.inbox-stats .stat {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 4px;
  flex: 1 0 0;
}

.inbox-stats .stat-label {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.inbox-stats .stat-number {
  align-self: stretch;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.inbox-conversations {
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.inbox-conversations .conversation {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.inbox-conversations .conversation:first-child {
  border-top: none;
}

.inbox-conversations .conversation:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.conversation .conversation-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.conversation .conversation-header .counts-wrapper {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.conversation .conversation-header .counts-wrapper svg {
  color: #838b9b;
  width: 18px;
  height: 18px;
}

.conversation-header .last-response-time {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Pomona-Black, #5c6880);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.conversation .prospect .prospect-info-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.conversation .lower {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
}

.conversation .prospects {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.conversation .prospect .prospect-info-wrapper .fake-pic svg {
  width: 32px;
  height: 32px;
}

.conversation .prospect .prospect-info-wrapper img {
  width: 32px;
  height: 32px;
  border-radius: 26px;
}

.conversation .prospect .prospect-info-wrapper .prospect-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.prospect-info-wrapper .prospect-name-org {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.prospect-info-wrapper .prospect-name-org .prospect-name {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.prospect-info-wrapper .prospect-name-org .org-name {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.prospect-info-wrapper .prospect-email {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.conversation-status .status-label {
  display: flex;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.conversation-status .status-label.viewed {
  background: linear-gradient(
      0deg,
      rgba(7, 160, 195, 0.3) 0%,
      rgba(7, 160, 195, 0.3) 100%
    ),
    #fff;
}

.conversation-status .status-label.booked {
  background: linear-gradient(
      0deg,
      rgba(255, 214, 1, 0.2) 0%,
      rgba(255, 214, 1, 0.2) 100%
    ),
    #fff;
}

.conversation-status .status-label.replied {
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.4) 0%,
      rgba(100, 182, 172, 0.4) 100%
    ),
    #f3f3f5;
}

.conversation-status .status-label.fail {
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.4) 0%,
      rgba(246, 126, 125, 0.4) 100%
    ),
    #fff;
}

.conversation-status .status-label.contacted {
  background: var(--Grays-Lighter, #e6e8eb);
}

.conversation:hover {
  cursor: pointer;
  background: var(--Very-Light-Grey);
}

.inbox-inner-wrapper.first-loading .stat-number {
  width: 20px;
  background: var(--Lisa-Yellow);
  color: var(--Lisa-Yellow);
  border-radius: 4px;
}

.fake-conversation:first-child {
  border-top: none;
}

.fake-conversation {
  width: 100%;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.fake-conversation .fake-header {
  background: var(--Lisa-Yellow);
  height: 20px;
  width: 100%;
  border-radius: 4px;
}

.fake-conversation .fake-body {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.fake-conversation .fake-body .fake-prospect-pic {
  width: 32px;
  height: 32px;
  border-radius: 26px;
  background: var(--Lisa-Yellow);
}

.fake-conversation .fake-body .fake-block {
  width: 200px;
  height: 20px;
  background: var(--Lisa-Yellow);
}

.inbox-conversations .empty-state {
  border: none;
}

.inbox-conversations .empty-state svg {
  color: var(--Light-Blue);
}

.inbox-conversations .empty-state div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.inbox-conversations .empty-state p {
  font-size: 14px;
  font-weight: 400;
}
