.search-runs-list {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.search-run-item {
  padding: 1rem 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid var(--border-color);
}

.search-run-item:hover {
  background-color: var(--hover-bg);
}

.search-run-item:last-child {
  border-bottom: none;
}

.search-run-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.search-type {
  font-weight: 500;
  font-size: 0.9375rem;
  color: var(--text-dark);
  margin-bottom: 0.25rem;
}

.status-indicator {
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.875rem;
  color: white;
}

.search-run-details {
  font-size: 0.8125rem;
  color: var(--text-muted);
  line-height: 1.5;
}

.error-message {
  color: var(--danger);
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-family: var(--font-monospace);
}

.search-details {
  padding: 1rem;
}

.search-parameters {
  margin: 0.5rem 1.25rem 1rem;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1rem;
}

.search-parameters pre {
  margin: 0;
  font-family: var(--font-monospace);
  font-size: 0.875rem;
  white-space: pre-wrap;
}

.page-section {
  margin-bottom: 0.5rem;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.page-section .accordion-button {
  padding: 0.75rem 1.25rem;
}

.page-section .accordion-button:not(.collapsed) {
  background: white;
  color: inherit;
  box-shadow: none;
}

.page-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.result-count {
  color: var(--text-muted);
  font-size: 0.8125rem;
}

.accordion-body {
  padding: 0;
  background: white;
}



.results-list {
  display: flex;
  flex-direction: column;
}

.result-item {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: all 0.2s ease;
}

.result-item:last-child {
  border-bottom: none;
}

.result-item:hover {
  background-color: var(--hover-bg);
}

.result-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.entity-name {
  font-weight: 500;
  font-size: 0.875rem;
}

.state-badge {
  font-size: 0.75rem;
  color: var(--text-muted);
  font-weight: normal;
}

.state-reason {
  font-size: 0.8125rem;
  color: var(--text-muted);
  margin-top: 0.125rem;
}

.result-details {
  padding: 1rem;
}

.result-details pre {
  background-color: var(--bg-light);
  padding: 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: pre-wrap;
}

.back-button {
  padding: 0;
  margin-right: 0.5rem;
  color: var(--primary);
  text-decoration: none;
  font-weight: normal;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: var(--text-muted);
}
