.conversation-wrapper .heading {
  display: flex;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.conversation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.conversation-wrapper .heading .set p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.conversation-wrapper .heading .set p:last-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.conversation-wrapper .heading .set .positive {
}

.conversation-wrapper .status-tag {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 3px;

  border-radius: 4px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.conversation-wrapper .status-tag.neutral {
  background: var(--Grays-Lightest, #f3f3f5);
}
.conversation-wrapper .status-tag.positive {
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.2) 0%,
      rgba(100, 182, 172, 0.2) 100%
    ),
    #fff;
}

.conversation-wrapper .status-tag.dream-positive {
  background: linear-gradient(
      0deg,
      rgba(255, 214, 1, 0.2) 0%,
      rgba(255, 214, 1, 0.2) 100%
    ),
    #fff;
}

.conversation-wrapper .status-tag.dream-positive {
  font-weight: 600;
}

.conversation-wrapper .messages-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}

.conversation-wrapper .message-wrapper .message {
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.conversation-wrapper .message b {
  font-weight: 500;
}

.conversation-wrapper .message {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.conversation-wrapper .message .header {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.conversation-wrapper hr {
  height: 1px;
  align-self: stretch;

  background: var(--Grays-Light, #c1c5cd);
  margin: 0px;
}

.conversation-wrapper .message h6 {
  display: none;
}

.conversation-wrapper .headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.conversation-wrapper .headers .count {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 4px;

  border-radius: 4px;
  background: linear-gradient(0deg, #f0eddc 0%, #f0eddc 100%), #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
