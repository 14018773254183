.imported-prospects-list,
.imported-prospect-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.imported-prospects-list .prospect-set,
.imported-prospect-data .value-set {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.imported-prospect-data .value-set {
  justify-content: flex-start;
}

.imported-prospects-list .prospect-set:first-child,
.imported-prospect-data .value-set:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top: 0px;
}

.imported-prospects-list .prospect-set:last-child,
.imported-prospect-data .value-set:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.imported-prospect-data .value-set label {
  font-weight: 500;
  width: 120px;
}
.imported-prospect-data .value-set p {
  flex: 1;
}

.imported-prospects-list .prospect-set .left-side .name {
  font-weight: 500;
}

.imported-prospects-list .prospect-set .left-side .email {
  font-size: 12px;
}

.imported-prospects-list .prospect-set .right-side span {
  color: var(--Dark-Blue);
  cursor: pointer;
}
