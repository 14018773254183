.product-lower-area {
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: -1px;
  align-self: stretch;
}

.product-header-row {
  display: flex;
  padding: 12px 40px 12px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.product-header-row p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.product-header-row p:first-child,
.product-row p:first-child {
  width: 200px;
}

.product-header-row p:not(:first-child),
.product-row p:not(:first-child) {
  flex: 1;
}

.product-lower-area .product-row {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.product-lower-area .product-row:hover {
  background: var(--Very-Light-Grey);
}

.product-lower-area .product-row p:not(:first-child) {
  font-size: 14px;
}

.product-lower-area .product-row p:first-child {
  font-weight: 500;
}

.product-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.product-page-lower-area {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex: 1;
}

.product-page-lower-area .dream-left-tabs {
  display: flex;
  width: 208px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Lightest, #f3f3f5);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.product-page-lower-area .tab-content {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 32px;
  flex: 1 0 0;
}

.product-form-page {
  width: 100%;
}
.product-form-page > .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 32px;
}

.product-form-page > .header h2 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.product-form-page > .header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.product-form-page .product-form-wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
}

.slidey-section {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-top: 1px solid var(--Grays-Light, #c1c5cd);
}

.slidey-section .contents {
  display: flex;
  padding-left: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.slidey-section .contents textarea {
  display: flex;
  max-width: 960px;
  height: 192px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.slidey-section .header {
  display: flex;
  padding-right: 4px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  cursor: pointer;
  width: 100%;
  user-select: none;
}

.slidey-section .header .main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  flex: 1;
}

.slidey-section .header .main-content h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.slidey-section .header .main-content p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.slidey-section .header .icon-frame svg {
  width: 32px;
  height: 32px;
}

.slidey-section.expanded .icon-frame svg {
  color: var(--Light-Green);
}

.slidey-section .change {
  display: flex;
  width: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.product-form-wrapper .shared-list {
  width: 100%;
  padding: 16px;

  border-radius: 12px;
  background: var(--Lisa-Yellow, #f7f5e9);
  border: none;
}

.product-form-wrapper .shared-list h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-form-wrapper .shared-list .header-row {
  margin-bottom: 8px;
}

.product-form-wrapper .shared-list input {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.product-form-wrapper .add-another-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.product-form-wrapper .add-another-button-wrapper button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);

  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-form-wrapper.market {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.product-form-wrapper.market .group small {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.product-form-wrapper.market .dream-list {
  margin-top: 16px;
}

.product-form-wrapper .thin-headers h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.product-form-wrapper.market .dream-list .dream-list-item {
  align-items: center;
}

.product-form-wrapper.market .remove-button {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Plus-White, #fffefc);
}

.product-form-wrapper.dream-side-modal-form {
  display: flex;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}
