.add-new-row {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-new-row-editor {
  display: flex;
  padding: 12px;
  padding-left: 20px;
  align-items: center;
  gap: 8px;
  background: rgba(241, 250, 252, 1);
}

.add-new-row-editor input {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  background: none;
  border: none;
  width: 250px;
}
.add-new-row-editor input::placeholder {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.add-new-loading-row.error {
  background: rgba(245, 115, 114, 0.2);
  color: var(--Wallstreet-Black);
}

.add-new-loading-row {
  background: var(--Grays-Lightest, #f3f3f5);
  width: calc(100% + 20px);
  margin-left: -20px;
  padding-left: 20px;
  display: flex;
  gap: 14px;
}

.add-new-loading-row .card-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-new-loading-row .card-left svg,
.add-new-loading-row .card-left svg path {
  fill: #5c6880 !important;
}

.add-new-loading-row.error svg path {
  fill: var(--Light-Red) !important;
}

.add-new-loading-row.error svg {
  width: 20px;
  height: 20px;
}

.add-new-loading-row .status-text,
.add-new-loading-row .status-text .status-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.add-new-loading-row .status-text .status-wrapper svg {
  color: #086788 !important;
}

.add-new-loading-row .status-text .status-wrapper svg path {
  fill: #086788 !important;
}
