.cft-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.cft-modal h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.cft-modal .filter-set {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;

  border-radius: 16px;
}

.cft-modal .filter-set .filter {
  display: flex;
  width: 262px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  cursor: pointer;
}

.cft-modal .filter svg {
  width: 32px;
  height: 32px;
  color: var(--Light-Blue);
}

.cft-modal .filter p.name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.cft-modal .filter p:not(.name) {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.cft-modal .filter.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.cft-modal .filter.active svg {
  color: var(--Wallstreet-Black);
}
