.research-related-prospect-search {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.research-related-prospect-search .top-description {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.research-related-prospect-search .datapoint-pills {
  margin-top: 12px;
}
