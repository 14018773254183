.visual-loading-indicator {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: linear-gradient(
    0deg,
    rgba(243, 243, 245, 0.5) 0%,
    rgba(230, 232, 235, 0.5) 100%
  );
}

.activity-section {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  gap: 12px;
  padding: 16px 0px;
}

.activity-card {
  width: 243px;
  flex: 0 0 auto;
  opacity: 0.25;
  display: flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: #000;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activity-card.center {
  border-radius: 16px;
  opacity: 0.75;
  background: #fff;

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.activity-card.done {
  border-radius: 16px;
  opacity: 0.75;
  background: #fff;
}
.activity-card.center {
  transform: translateX(0);
  opacity: 1;
}

.activity-card {
  transition: margin 1s cubic-bezier(0.22, 0.68, 0, 1.71);
}

.visual-loading-indicator .title {
  display: flex;
  width: 275px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.visual-loading-indicator h3 {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activity-card .icon-frame {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: linear-gradient(180deg, #f3f3f5 0%, #e6e8eb 100%);
}
.activity-card .icon-frame svg {
  width: 21px;
  height: 18px;
  flex-shrink: 0;

  color: var(--Performa-Black, #152647);
}

.activity-card.done .icon-frame svg {
  color: var(--Light-Blue);
}

.visual-loading-indicator .images {
  display: flex;
  align-items: flex-start;
}
.visual-loading-indicator .images > *:nth-child(2) {
  margin-left: -12px;
}
.visual-loading-indicator .images svg,
.visual-loading-indicator .images img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
}

.show-active-draft .draft-which {
  display: flex;
  padding: 12px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.in-final-viewer .draft-label {
  cursor: pointer;
}
.show-active-draft .draft-label.final svg {
  width: 20px;
  height: 20px;
  color: var(--Light-Green);
}

.show-active-draft .draft-label {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
}
.show-active-draft .draft-label .spinner-border {
  color: var(--Light-Blue);
  /* background: var(--Light-Blue); */
}
.show-active-draft .draft-label.done {
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  opacity: 0.75;
  background: var(--Grays-Lightest, #f3f3f5);
}

.draft-label.active {
  border-radius: 16px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  opacity: 0.75;
  background: var(--Grays-Lightest, #f3f3f5);
}
.show-active-draft .draft-label.visible {
  border-radius: 16px;
  opacity: 0.75;
  background: #fff;

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.draft-display {
  display: flex;
  padding: 32px 64px;
  align-items: flex-start;
  flex-direction: column;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;
}

.draft-display > *:first-child {
  font-size: 16px;
}

.supervision-notes {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 0px;
}

.feedback-wrapper {
  margin-top: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--Lisa-Yellow, #f7f5e9);
}

.feedback-wrapper h6 {
  font-size: 14px;
}

.draft-label svg {
  height: 18px;
  width: 17px;
}

.draft-label p {
  display: flex;
  gap: 4px;
  align-items: center;
}

.draft-view .info-expander-wrapper {
  margin-top: 24px;
}

.draft-view .info-expander-wrapper .header h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.draft-view .info-expander-wrapper .header svg {
  width: 20px;
  height: 20px;
}
