.modal-content.congrats-modal {
  background: var(--Wallstreet-Black, #000d26);
  color: white;
}

.congrats-modal svg {
  color: var(--Light-Red);
}

.congrats-modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.congrats-modal .modal-body h4 {
  color: var(--Plus-White, #fffefa);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.congrats-modal .modal-body p {
  color: var(--Lisa-Yellow, #f0eddc);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.button-row button {
  color: var(--Light-Blue, #07a0c3);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}

#page-content-wrapper canvas {
  z-index: 1054 !important;
}
