.dream-agent-context {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--Lisa-Yellow, #f0eddc);
}

.dream-agent-context .info-spot {
  align-self: stretch;
}

.dream-agent-context .info-spot .header {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  width: 100%;
  align-self: stretch;
}
.dream-agent-context .info-spot .header > div:first-child {
  display: flex;
  align-items: center;

  gap: 12px;
  flex: 1;
}

.dream-agent-context svg {
  color: var(--WallStreet-Black) !important;
  height: 24px;
  width: 24px;
}

.dream-agent-context h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.dream-agent-context .content {
  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.dream-agent-context .content a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}
