.prospect-view {
  width: 100%;
}

.prospect-view .prospect-lower-area {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.prospect-view .tab-content {
  flex: 1;
}

.email-oagent-prospect {
  width: 100%;
}

.email-oagent-prospect .top-options-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
}

.email-oagent-prospect .set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.email-oagent-prospect .set h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.email-oagent-prospect .set p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.email-oagent-prospect .options-set {
  display: flex;
  justify-content: space-between;
}

.email-oagent-prospect .options-set .profile-select {
  display: flex;
  align-items: center;
  gap: 12px;
}

.email-oagent-prospect .options-set .profile-select label {
  width: 150px;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  margin-bottom: 0px;
}

.status-area .status-buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.status-area .status-buttons p {
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  margin-bottom: 0px;
}

.email-oagent-prospect {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1 0 0;
}

.email-oagent-prospect h3 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.email-oagent-prospect .key-options-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.email-oagent-prospect .key-options-wrapper .top-options-line {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.email-oagent-prospect .context-description-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}

.email-oagent-prospect .contexts {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}

.email-oagent-prospect .context-set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.email-oagent-prospect .context-set .context-main .context-internal {
  align-self: stretch;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-oagent-prospect .context-set .context-main {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.email-oagent-prospect .start-generate {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.email-oagent-prospect .start-generate > svg {
  width: 40px;
  height: 40px;
  color: black;
}

.email-oagent-prospect .start-generate button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.emails-area .emails-wrapper {
  margin-top: 24px;
}

.emails-area .emails-wrappers {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.outreach-section {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: #fff;
}

.email-options-select {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow: auto;
}

.email-options-select > div {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: #fff;
}

.email-option h6 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.email-option h6 img {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.email-option .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.email-option h6 .pseudo-pic {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Grays-Medium, #838b9b);
}

.email-option h6 .pseudo-pic svg {
  width: 24px;
  height: 24px;
  color: var(--Plus-White);
}

.email-option hr {
  height: 1px;
  align-self: stretch;
  background: var(--Grays-Light, #c1c5cd);
  margin: 0px;
}

.set.main-header small {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.email-oagent-prospect .options-set {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.options-set .status-area {
  display: flex;
  align-items: center;
  gap: 12px;
}

.options-set .status-area label {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
.options-set .status-area p.negative {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 4px;

  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.2) 0%,
      rgba(246, 126, 125, 0.2) 100%
    ),
    #fff;
}

.options-set .status-area p.positive {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 4px;

  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.2) 0%,
      rgba(100, 182, 172, 0.2) 100%
    ),
    #fff;
}

.context-set .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.context-set .header-wrapper .regenerate {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Dark-Blue);
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.context-main .loading-wrapper {
  display: flex;
  justify-content: center;
}

.outreach-section > .empty-state {
  border: none;
}

.outreach-section .header h5 {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.outreach-section .header h5 svg {
  color: var(--Light-Blue);
}

.outreach-section hr {
  margin: 0px;
  background: var(--Grays-Light, #c1c5cd);
}

.outreach-section .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.outreach-section .header button {
  display: flex;
  padding: 8px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.outreach-section .pre-generated-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  position: relative;

  border-radius: 4px;
}
.guidance-wrapper:first-of-type {
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.guidance-wrapper .body p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.guidance-wrapper .guidance {
  display: flex;
  padding: 16px;
  align-self: stretch;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
}

.guidance-wrapper .guidance.body {
  min-height: 300px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.outreach-section .edit-email {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;

  border-radius: 4px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Grays-Lightest, #f3f3f5);
}

.outreach-section .form-control-lg {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 16px;
}

.outreach-section textarea {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  min-height: 450px;
}

.outreach-section .dream-side-modal-form label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.email-editor-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.email-editor-outer-wrapper h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.email-editor-outer-wrapper .save-button-wrapper {
  margin-top: -12px;
}

.email-editor-outer-wrapper .save-button-wrapper button {
  display: flex;
  padding: 8px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.schedule-email-modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.schedule-email-modal-body > p:first-child {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.schedule-email-modal-body .dream-side-modal-form {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
}

.schedule-email-modal-body .dream-side-modal-form label span {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scheduled-info-holder .button-links {
  display: flex;
  align-items: center;
  gap: 8px;
}

.scheduled-info-holder .button-links .btn-secondary {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.scheduled-info-holder .button-links .btn-secondary svg {
  color: #000d26;
}

.scheduled-info-holder .button-links .btn-secondary .more-complicated {
  display: flex;
  gap: 4px;
}

.email-status-loading-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--Light-Blue);
}

.message-history-outer-wrapper .conversation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.conversation-wrapper .messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.conversation-wrapper .messages .message {
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: #fff;
}

.messages .message.inbound h6 svg {
  color: var(--Light-Red);
}

.messages .message.outbound h6 svg {
  color: var(--Light-Blue);
}

.messages .message h6 {
  display: flex;
  align-items: center;
  gap: 8px;
}

.messages .message.inbound {
  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.05) 0%,
      rgba(246, 126, 125, 0.05) 100%
    ),
    #fff;
}

.messages .message hr {
  height: 1px;
  align-self: stretch;

  background: var(--Grays-Light, #c1c5cd);
  margin-bottom: 0px;
  margin-top: 0px;
}

.messages .message .header {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  line-height: 24px; /* 171.429% */
}

.messages .message .header b {
  font-weight: 500;
}

.messages .message .body {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.emails-area .small-reminder {
  margin-bottom: -12px;
}

.emails-area .small-reminder svg {
  width: 24px;
  height: 24px;
  color: var(--Grays-Medium);
  margin-right: 6px;
}

.prospect-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.prospect-details .information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.prospect-details .info-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.prospect-details .info-set .label {
  display: flex;
  width: 192px;
  padding: 8px 16px;
  align-items: flex-start;
  background: var(--Lisa-Yellow, #f0eddc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.prospect-details .info-set .value {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.prospect-details .info-set .value a {
  color: var(--Dark-Blue);
  text-decoration: none;
}

.prospect-details .set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.prospect-details .set p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.settings-wrapper form > div {
  font-size: 14px;
}

.settings-wrapper form > div > label {
  font-weight: 500;
}

.prospects-list-wrapper .status-tag {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 4px;
}

.prospects-list-wrapper .neutral {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #fff;
}
.prospects-list-wrapper .positive {
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.4) 0%,
      rgba(100, 182, 172, 0.4) 100%
    ),
    #fff;
}

.prospects-list-wrapper .negative {
  background: linear-gradient(
      0deg,
      rgba(246, 126, 125, 0.4) 0%,
      rgba(246, 126, 125, 0.4) 100%
    ),
    #fff;
}

.email-editor-outer-wrapper {
  position: relative;
}

.email-editor-outer-wrapper h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.email-editor-outer-wrapper h6 .regenerate {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Dark-Blue);
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.prospect-lower-area .top-header-area {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 48px 16px 16px 48px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Grays-Lightest, #f3f3f5);
}

.prospect-lower-area .top-header-area .logo-set {
  display: flex;
  width: 323px;
  align-items: flex-start;
  gap: 12px;
}

.prospect-lower-area .logo-set img {
  width: 64px;
  height: 64px;
  border-radius: 128px;
}

.prospect-lower-area .logo-set div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.prospect-lower-area .logo-set div h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prospect-lower-area .logo-set div p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.prospect-lower-area .logo-set div small {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.prospect-lower-area .top-header-area .info-sets {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.prospect-lower-area .top-header-area .info-sets .set {
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.prospect-lower-area .top-header-area .info-sets .set label {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prospect-lower-area .top-header-area .info-sets .set p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.prospect-lower-area .top-header-area .info-sets .set p a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.prospect-lower-area .campaigns {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}
.prospect-lower-area .campaigns > h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.prospect-lower-area .campaigns .campaigns-wrapper {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
}
