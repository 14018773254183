:root {
  /* Old Singificant Colours */

  /* Primary Colors */
  --royal-purple: #7851a9;
  --dark-purple: #4b0082;

  /* Secondary Colors */
  --lavender: #e6e6fa;
  --light-lavender: #e6e6fa70;
  --lilac: #c8a2c8;

  /* Accent Colors */
  --gold: #ffd700;
  --silver: #c0c0c0;

  /* New Significant Colours */

  --Wallstreet-Black: rgba(0, 15, 35, 1);
  --Performa-Black: rgba(17, 29, 56, 1);
  --Plus-White: #FFFEFC;
  --Lisa-Yellow: #F7F5E9;
  --Navbar-Yellow: #FAF9F5;

  --Dream-Yellow: rgba(255, 208, 4, 1);
  --Dark-Blue: #086788;
  --Light-Blue: rgba(11, 150, 187, 1);
  --Dark-Red: rgba(136, 29, 27, 1);

  --Light-Red: #F67E7D;
  --Light-Green: rgba(89, 173, 162, 1);

  /* Neutral Colors */
  --Very-Light-Grey: rgba(241, 241, 243, 1);
  --Lighter-Grey: rgba(226, 229, 232, 1);
  --Light-Grey: rgba(185, 189, 198, 1);
  --Dark-Grey: rgba(120, 128, 144, 1);

/* Alert Colors */
  --alert-yellow: var(--Dream-Yellow); /* A vibrant yellow for alert messages */

  /* Danger Colors */
  --danger-red: var(--Dark-Red); /* A strong red for danger messages */
}

body {
  margin: 0;
  font-family:
    'Spline Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--Wallstreet-Black);
}

p {
  margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

label {
  margin-bottom: 0px;
}

input::placeholder,
textarea::placeholder {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

textarea {
  padding: 12px 16px !important;
  outline: none;
  resize: none;
  box-shadow: none;
  border-radius: 8px;
}

input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.global-container {
  display: flex;
  justify-content: space-between;
  max-height: 100vh;
}

#sidebar-wrapper {
  display: flex;
  width: 224px;
  padding: 32px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  align-self: stretch;
  border-right: 1px solid var(--Grays-Light, #C1C5CD);
  background: var(--Navbar-Yellow);
  // box-shadow: 2px 0px 0px 0px rgba(21, 38, 71, 0.10);
}


#page-content-wrapper {
  height: 100vh;
  overflow-y: auto;
  flex: 1;
  overflow: auto;
}

.navbar-inner {
  width: 100%;
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#sidebar-wrapper {
  transition: margin 0.5s ease-in-out;
}

#sidebar-wrapper.hidden {
  margin-left: -228px;
}

.cheeky-spacer-sorry-matt {
  height: 20px;
}

.dream-logo-wrapper {
  padding: 0px 20px;
}

.dream-logo-wrapper img {
  width: 138px;
}

.main-links {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 12px;
}

.link-group {
  display: flex;
  flex-direction: column;
}

.link-group.dashboard a {
  color: var(--Wallstreet-Black, #000D26);
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 8px;
  transition: none !important;
}

.link-group.dashboard a.active,
.link-group.plain a.active {
  border-radius: 8px;
  background: var(--Dream-Yellow);
  color: var(--Wallstreet-Black) !important;
}

.link-group:not(.dashboard) {
  display: flex;
  flex-direction: column;
}

.link-group h4 {
  color: var(--Performa-Black, #152647);
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 0px;
}

.link-group:not(.dashboard) a > svg {
  width: 20px;
  height: 20px;
}

.link-group:not(.dashboard) a {
  color: var(--Wallstreet-Black, #000D26);
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  transition: none !important;
}

.link-group.agents a.active path {
  fill: var(--Wallstreet-Black);
}

.link-group a.sdrs.active {
  background: linear-gradient(270deg, #64b6ac 0%, #77d9cd 100%);
  color: var(--Wallstreet-Black);
}

.link-group a.analyst.active {
  background: linear-gradient(119deg, #ffa6a6 17.87%, #f67e7d 81.86%);
  color: var(--Wallstreet-Black, #000d26);
}

.link-group a.list-builder.active {
  background: linear-gradient(118deg, #59e0ff 17.03%, #07a0c3 82.7%);
  color: var(--Wallstreet-Black, #000d26);
}

.bottom-links {
  padding: 0px 12px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-section {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}

.bottom-section .funnels-section {
  width: 66%;
}

.bottom-section .prospect-section {
  width: calc(33% - 32px);
}

.funnels-section h2 {
  margin-bottom: 16px;
}

.funnels-section .funnels-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 82vh;
  overflow: auto;
  padding: 16px;
}

.funnels-section .funnels-list .funnel {
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;
}

.funnels-section .funnels-list .funnel {
  cursor: pointer;
}
.funnels-section .funnels-list .funnel-details {
  display: flex;
  align-items: center;
  gap: 26px;
}

.funnel-details > * {
  width: 150px;
}

.funnel-details p {
  margin: 0px;
  text-transform: uppercase;
  font-size: 0.9em;
}

.funnel-details .value {
  font-size: 1.5em;
  font-weight: 800;
}

.center-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}

.prospects-list table {
  width: 100%;
}

.prospects-list table tr {
  border-bottom: 1px solid rgba(192, 192, 192, 1);
}

.prospects-list table tr td {
  padding: 16px 0px;
  padding-bottom: 10px;
}

.add-funnel-area {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.wide-add-button {
  width: 100%;
  font-weight: bold;
  padding: 24px;
  border: 1px solid var(--Wallstreet-Black);
  background: transparent;
  color: var(--Wallstreet-Black);
}

.funnel-creation-area {
  width: 55%;
}

.funnel-creation-wrapper {
  display: flex;
  gap: 48px;
  justify-content: space-between;
  margin-top: 24px;
  min-width: 960px;
}

.funnel-creation-area .funnel-name {
  margin-bottom: 16px;
}
.funnel-creation-area .section {
  border-top: 1px solid rgb(230, 230, 250);
  padding: 20px 0px;
}

.additional-options-wrapper {
  padding: 16px;
  background: var(--Very-Light-Grey);
}

// .additional-options-wrapper > * > .inner-opt-group {
//   background: white;
//   padding: 16px;
//   border-radius: 6px;
//   border: 1px solid var(--royal-purple);
// }
.additional-options-wrapper label:not(.form-check-label) {
  font-weight: 500;
}

.additional-options-wrapper textarea {
  resize: none;
  box-shadow: none !important;
}

.stage-multi-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
}

.stage-multi-wrapper > .step-item {
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.step-item .header-area {
  display: flex;
  align-items: center;
  gap: 12px;
}

.step-item .header-area h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step-item .header-area span {
  width: 32px;
  height: 32px;
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Dark-Blue);
}

.step-item .links {
  display: flex;
  align-items: center;
  gap: 32px;
}

.step-item .delete-link:hover,
.step-item .edit-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.step-item .delete-link {
  font-size: 0.8em;
  color: var(--Dark-Red);
  margin-right: 10px;
}
.step-item .edit-link {
  font-size: 0.8em;
  color: var(--Dark-Blue);
}

.stage-multi-wrapper > div .options-holder {
  flex: 1;
}

.right-side-wrapper {
  flex: 1;
}

.right-side-wrapper .use-case-editor-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.right-side-wrapper .use-case-editor-form button {
  align-self: flex-end;
}

.use-case-area h6 {
  border-bottom: 1px solid var(--royal-purple);
  padding-bottom: 12px;
  margin-bottom: 12px;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
}

.use-case-area h6 span {
  color: var(--royal-purple);
  text-transform: uppercase;
  font-size: 0.8em;
}

.estimate-area {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: center;
  position: sticky;
  top: 40px;
  margin-left: 24px;
  margin-top: 24px;
}

.estimate-area .estimate {
  width: calc(50% - 12px);
  text-align: center;
}

.estimate-area .explainer p {
  text-align: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgb(230, 230, 250);
}


.page-split .spacer-sorry-matt {
  width: 1px;
  align-self: center;
  height: 30%;
  background: var(--Grays-Light, #c1c5cd);
}

.big-prospects-loading {
  width: 100%;
  background: rgba(253, 253, 253, 1);
  border: 1px solid #eee;
  padding: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.funnel-view-page .prospects-area {
  margin-top: 16px;
}

.right-side .stages .stage {
  margin-bottom: 24px;
}

.arrow-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-holder > * {
  width: 22px;
  height: 22px;
}

.stats-area {
  transition: color 0.2s ease;
}

.stats-area.highlight > .stat .value {
  color: #ffd700;
}

.lower-area.full-height {
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
}

.client-view-top-bar {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  background: #7851a9;
  border-bottom: 1px solid #4b0082;
  padding: 16px 0px;
}

.client-view-top-bar a {
  text-decoration: none;
  font-size: 16px;
  display: flex;
  color: white;
  padding-left: 24px;
  font-weight: bold;
  align-items: center;
}

.client-view-top-bar a span {
  margin-left: 16px;
}

.client-view-top-bar a:hover {
  text-decoration: underline;
}

.image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  background-color: #f2f2f2; /* Background color for the image section */
}

.image-section img {
  max-width: 100%;
  max-height: 100%;
}

.image-section h1 {
  color: #666;
}

.funnel-time-options-area {
  padding: 16px 0px;
}

.funnel-time-form {
  display: flex;
  gap: 32px;
}

.funnel-time-form label {
  font-weight: bold;
  font-size: 0.9em;
}

.funnel-time-form > * {
  flex: 1;
  background: #e6e6fa3d;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #dee2e6;
}

.button-for-pause-and-start {
  width: 120px;
}

.go-login-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: var(--Wallstreet-Black);
}

.go-login-center {
  background: white;
  padding: 32px 64px;
  border-radius: 14px;
  color: black;
  text-align: center;
  margin-top: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.go-login-center img {
  width: 140px;
  margin-bottom: 16px;
}

.go-login-center span {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.full-width-company {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: var(--Plus-White, #FFFEFC);
  display: flex;

}

.full-width-company > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-width-company > div:last-child {
  flex: 1;
}

.full-width-company .dream-panel-outer {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.full-width-company .dream-panel {
  display: flex;
  position: relative;
  width: 546.4px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Wallstreet-Black, #000D26);
}

.full-width-company .dream-panel > svg {
  position: absolute;
  height: 100%;
}
.full-width-company .dream-panel .url {
  color: #FFF;
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  position: absolute;
  bottom: 40px;
}

.full-width-company .dream-panel .dream-description {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.full-width-company .dream-panel img {
  width: 222.746px;
}

.full-width-company .dream-panel p {
  color: var(--Dream-Yellow, #FFD601);
  font-family: "Spline Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.company-form {
  display: flex;
  flex-direction: column;
  min-width: 558px;
}

.company-form h1 {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 32px;
}

.company-form > div {
  padding-bottom: 16px;
}

.company-form input, .company-form select {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  background: #FFF;
}
.company-form label {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.please-specify {
  margin-top: 12px;
}
/* Add this CSS to your index.scss or a relevant CSS file */
.icon-box {
  width: 48px;
  height: 48px;
  background: linear-gradient(
    135deg,
    #e6e6fa,
    #7851a9
  ); /* Lavender to Royal Purple */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #4b0082; /* Dark Purple for the border */
  border-radius: 5px;
  margin-right: 10px;
  color: #fff; /* White color for the icon inside */
}

.icon-box > * {
  width: 24px;
  height: 24px;
}

.wizard-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.wizard-item:hover {
  background-color: #f8f9fa;
}

.dream-wizard-choose {
  width: 600px;
}

.dream-wizard-choose h2 {
  margin-bottom: 8px;
}

.dream-wizard-choose p {
  margin-bottom: 20px;
}

.dream-wizard-choose .btn-link {
  margin-bottom: 20px;
}

.wizard-description h5 {
  margin: 0;
}

.wizard-description {
  max-width: 400px;
  margin-left: 20px;
}

.alert {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;

  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0px;
}

.alert-info {
  border-radius: 8px;
  border: 1px solid var(--Light-Blue, #07A0C3);
  background: linear-gradient(0deg, rgba(7, 160, 195, 0.10) 0%, rgba(7, 160, 195, 0.10) 100%), #FFF;
}

.btn-link {
  padding-right: 0px;
  padding-left: 0px;
}

button.already-know {
  margin-top: 16px;
}

.dream-wizard-choose .button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.dream-panel-inner {
  width: 500px;
}

.forward-and-back-buttons.single-button {
  justify-content: center;
}

.forward-and-back-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
}

.dream-panel.hidden {
  display: none;
}

.dream-wizard-demo label {
  font-weight: bold;
}

.demo-wizard-form > div {
  margin-bottom: 12px;
}

.overlay {
  position: absolute;
  top: -16px;
  left: -16px;
  right: -16px;
  bottom: -16px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-form.loading {
  opacity: 0.2;
}

.demo-wizard-form .examples {
  margin-top: 12px;
}

.breathing-room {
  padding: 8px 0px;
}
.dream-wizard-funnel-build .container {
  width: 1200px;
  margin: auto;
}

.dream-wizard-funnel-build .container {
  max-width: 1200px;
  margin: auto;
}

.dream-wizard-funnel-build .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.funnel-stage {
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 12px;
  position: relative;
}

.highlighted {
  border-radius: 5px;
  background-color: #d1c4e9; /* Light shade of Royal Purple */
  color: black;
  border: 1px solid #4b0082;
}

.greyed-out {
  background-color: #f3f0f7;
  color: #696969;
  border: 1px solid #d3d3d3;
  cursor: not-allowed;
  opacity: 0.5;
}

.funnel-build-explanation {
  margin-left: 16px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 16px;
  height: 664px;
}

.funnel-stage h4 {
  font-size: 1.3em;
  font-weight: 700;
}

.funnel-stage p:first-of-type {
  font-size: 1.2em;
  color: black;
}

.funnel-stage p {
  margin: 4px;
}

.agent-wrapper {
  margin-top: 16px;
  min-height: 664px;
}

.agent-wrapper iframe {
  border: 1px solid #ddd;
}

.thumbnail-image-section {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 260px;
  height: 300px;
  overflow: hidden;
  border: 8px solid #7951a987; /* Optional border for the thumbnail */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(248, 248, 248, 0.7);
}

.thumbnail-image-section h1 {
  font-size: 1.5em;
}

.thumbnail-image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.funnel-build-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 24px;
}

.use-case-options {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}

.use-case-options > button {
  height: 128px;
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.use-case-options > button > *:first-child {
  width: 52px;
  height: 52px;
}

.use-case-description {
  margin: 12px 0px;
  padding: 12px;
  height: 150px;
}

.step-2-button-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: none !important;
}

.company-form .step-2-button-wrapper > button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.company-form.big-textarea textarea::placeholder {
  color: var(--Grays-Medium, #838B9B);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.company-form.big-textarea textarea {
  display: flex;
  height: 160px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  background: #FFF;
}

.step-1-button-wrapper {
  margin-top: 16px;
}

.step-1-button-wrapper button {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000D26);
  color: var(--Plus-White, #FFFEFC);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.error-area {
  border-bottom: none !important;
  margin-top: -12ppx;
}

.running-button {
  display: flex;
  align-items: center;
}

.running-button > *:first-child {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.orgs-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 22px;
  margin-top: 26px;
}

.orgs-list span {
  border-radius: 32px;
  border: 1px solid #7851a9;
  background: #e6e6fa;
  padding: 12px 16px;
}

.orgs-list span.negative {
  background: var(--Light-Grey);
  border-color: var(--Dark-Grey);
}

.company-form.in-wizard {
  width: 100%;
}

.company-form.in-wizard textarea {
  height: 150px;
}

.company-form .use-case-options {
  margin-bottom: 32px;
}

.company-form .use-case-options button {
  display: flex;
  height: 128px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  
  border-radius: 12px;
  background: var(--Lisa-Yellow, #F7F5E9);
  border: none;
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.company-form .use-case-options button.selected {
  border-radius: 12px;
  border: 1px solid var(--Performa-Black, #152647);
  background: var(--Dream-Yellow, #FFD601);
}

.company-form .use-case-options button.selected svg {
  color: var(--Wallstreet-Black, #000D26);
}

.company-form small {
  display: block;
  color: var(--Performa-Black, #152647);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.company-form .use-case-options button svg {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  color: var(--Dark-Blue);
}

.create-funnel-step-one {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 24px;
}

.create-funnel-step-one label {
  font-weight: bold;
}

.create-funnel-step-one button {
  padding: 16px 0px;
}
.create-funnel-step-one > div textarea {
  resize: none;
  box-shadow: none !important;
}

.create-funnel-step-one div.use-case-options {
  display: flex;
}

.create-funnel-step-one div.use-case-options,
.create-funnel-step-one:not(.modal-body) > div textarea {
  width: 100%;
  justify-content: space-between;
}

.create-funnel-step-one > div textarea {
  height: 160px;
  margin-bottom: 4px;
}

/* CSS for blurring the Step2 component */
.blurred {
  filter: blur(8px);
  pointer-events: none; /* Prevents interaction */
}

.ficon-wrapper {
  margin-left: 6px;
}

// .add-first-filter-wrapper .form-area {

// }

.add-first-filter-wrapper .buttons-area {
  display: flex;
  align-items: center;
  gap: 12px;
}

.add-first-filter-wrapper .buttons-area button {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.add-first-filter-wrapper .buttons-area button span > * {
  width: 32px;
  height: 32px;
}

.add-first-filter-wrapper .additional-options-area {
  padding: 16px;
  margin-top: 26px;
}

.add-first-filter-wrapper label {
  font-weight: 700;
}

.edit-step-controls {
  display: flex;
  gap: 24px;
}

.editing-modal {
  max-width: 860px;
  width: 860px;
  gap: 24px;
}

.edit-step-controls .left-side {
  flex: 1;
  min-width: 45%;
}

.edit-step-controls .right-side {
  flex: 1;
}

.additional-options-wrapper {
  margin-top: 12px;
}

.test-button-wrapper {
  margin-top: 12px;
  display: flex;
}

.agent-mimic {
  margin-top: 12px;
  background: var(--Lisa-Yellow);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
}
.agent-mimic h6 {
  margin-bottom: 12px;
}
.agent-mimic .response-wrapper {
  margin-top: 7px;
}

.pages-visited-area .images-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pages-visited-area .images-wrapper .thumbnail {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.pages-visited-area .images-wrapper .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.test-form-inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.agent-mimic {
  max-width: 100%;
}
.agent-mimic .steps {
  margin-top: 16px;
}

.agent-mimic p {
  max-width: 100%;
}

.credits {
  margin-top: 16px;
  text-align: center;
  border: 1px solid var(--Performa-Black, #152647);

}

.right-links > * {
  margin-right: 12px;
}

.credits {
  padding: 8px 16px;
  background: var(--Lisa-Yellow);
  border-radius: 8px;
  text-decoration: none;
  color: var(--Wallstreet-Black);
}

.credits:hover {
  text-decoration: underline;
}

.credit-bar {
  width: 100%;
  text-align: center;
  padding: 24px 0px;
}

.credit-bar.credit-alert-bar {
  background: lighten(#ffcc00, 40%);
  color: darken(#ffcc00, 20%);
  font-weight: 500;
}

.credit-bar.credit-danger-bar {
  background: lighten(#ff4c4c, 20%);
  color: darken(#ff4c4c, 20%);
  font-weight: 500;
}

.credit-bar p {
  margin-bottom: 0px;
}
.credit-bar a {
  color: currentColor !important;
}

.buy-credits-button-wrapper {
  display: flex;
  justify-content: center;
}

.credits-modal-backdrop {
  z-index: 1055;
}

.link-set {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  border: 1px solid var(--Light-Grey);
  padding: 16px;
  border-radius: 6px;
}

.link-set p {
  margin-bottom: 0px;
}

.indicator-wrapper {
  padding: 24px 0px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.indicator-wrapper.active {
  color: var(--royal-purple);
}

.indicator-wrapper.dormant {
  color: var(--Dark-Grey);
}

.indicator-wrapper span.active {
  margin-left: 8px;
}

.test-form-margin {
  margin-top: 10px;
}

.funnel-view-page .additional-options-toggle {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
}

.funnel-view-page .additional-options-toggle button {
  font-size: 0.9em;
  text-decoration: none;
  padding: 0px;
}

.funnel-view-page .additional-options-toggle .dropdown-toggle::after {
  border: none !important;
}

.stage h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-inner-icon {
  margin-right: 8px;
  color: var(--dark-purple);
}

.header-with-options {
  display: flex;
  justify-content: space-between;
}

.step-edit-line {
  display: flex;
  justify-content: space-between;
}

.stage-entry {
  display: flex;
  justify-content: space-between;
}

.stage-entry .step-right {
  width: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.stage-entry .step-right > * {
  color: var(--royal-purple);
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.empty-state {
  display: flex;
  width: 100%;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.empty-state p {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.edit-control {
  position: relative;
}

.edit-control.edit-off:hover .edit-icon {
  display: inline;
}

.edit-control.edit-on {
  position: relative;
}

.edit-control.edit-off {
  padding-right: 38px;
}
.edit-control.edit-on {
}

.edit-control .edit-icon {
  width: 16px;
  margin-left: 6px;
  display: none;
  cursor: pointer;
  color: var(--Light-Blue);
}

.edit-control .editing-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.edit-control .editing-form .input-and-desc {
  flex: 1;
}

.wide-add-button > svg {
  margin-right: 8px;
}

.dream-list {
  padding: 16px;
  background: white;
  border: 1px solid var(--Light-Grey);
  border-radius: 16px;
}

.calls-list {
  max-width: 1200px;
}

.dream-list-item {
  background: var(--Plus-White);
  border: 1px solid var(--Light-Grey) !important;
  border-radius: 6px;
  padding: 16px;
}

.dream-tabs {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
  border-radius: 0px !important;
}

.dream-tabs.thin .nav-link {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.dream-tabs .in-tab-icon {
  margin-left: 6px;
}
.dream-tabs .nav-item {
  border-radius: 0px !important;
}

.dream-tabs .nav-link {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-radius: 0px !important;
}

.dream-tabs .nav-link.active {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 2px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.dream-tabs .nav-link:not(.active) {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.shared-list {
  display: flex;
  flex-direction: column;
}

.shared-list .header-row {
  display: flex;
  gap: 12px;
}

.shared-list .body-rows {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.shared-list .header-row > * {
  flex: 1;
}

.shared-list-row {
  display: flex;
  gap: 12px;
}
.shared-list-row > * {
  flex: 1;
}

.dream-list.thin-headers {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dream-list.thin-headers .dream-list-item {
  padding: 16px;
}

.dream-list.thin-headers .dream-list-item h5 {
  margin-bottom: 0px;
}

.dream-list-item.has-action-icons {
  display: flex;
  justify-content: space-between;
}

.dream-loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  background: var(--Lisa-Yellow, #f0eddc);
  padding: 16px;
  min-height: 200px;
  font-weight: 500;
}

.wide-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--royal-purple);
  font-weight: 500;
}

.wide-loading .wide-loading-central {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 24px;
  border: 1px solid var(--Wallstreet-Black);
  color: var(--Wallstreet-Black);
  border-radius: 12px;
}

.wide-loading .wide-loading-central h6 {
  font-size: 1.4em;
  margin-top: 12px;
}

.tests-tab-wrapper {
  background: var(--Very-Light-Grey);
  padding: 16px;
}

.tests-tab-content {
  background: white;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--royal-purple);
}

.meaning-wrapper {
  background: var(--Very-Light-Grey);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.meaning-wrapper > * {
  background: white;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--royal-purple);
}

.meaning-wrapper textarea {
  resize: none;
  box-shadow: none;
}

.funnel-view-page .header-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.funnel-view-page .header-line .selected-actions {
  color: var(--royal-purple);
}

.funnel-view-page .header-line .selected-actions {
  cursor: pointer;
}

.stage-entry .loading-wrapper {
  display: flex;
  justify-content: center;
}

.questions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.questions-header > h4 {
  margin-top: 4px;
}
.questions-header > .search-input {
  width: 300px;
}

.dream-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dream-breadcrumbs > span:not(:last-of-type)::after {
  content: '>';
  margin-left: 6px;
  font-weight: 500;
}

.dream-breadcrumbs > span:last-of-type {
  font-weight: 600;
}

.congratulations {
  padding: 128px;
}

.congratulations svg {
  color: var(--royal-purple);
}

.congrats-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.congrats-inner h1 {
  color: var(--Wallstreet-Black, #000D26);
  text-align: center;
  font-family: "Spline Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.congrats-inner p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  width: 547px;
}

.congrats-inner .get-started {
  margin-top: 20px;
}

.congrats-inner button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Plus-White, #FFFEFC);
font-family: "Spline Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000D26);
}

.congrats-inner button svg {
  color: var(--Dream-Yellow);
  margin-left: 12px;
}

.funnels-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.page-header-area {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Light, #C1C5CD);
}

.page-header-area .title-and-text {
  display: flex;
  width: 700px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.page-header-area .title-and-text h3 {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.page-header-area .title-and-text p {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.page-header-area .buttons-area {
  display: flex;
  align-items: center;
  gap: 16px;
}

.page-header-area .buttons-area button {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 8px;
  align-self: stretch;
}

.analyst .btn-primary svg {
  color: var(--Dream-Yellow);
}

.funnel .btn-primary svg {
  color: var(--Dream-Yellow);
}

.list-area {
  width: 100%;
}

.list-area .funnel-list-view {
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: -1px;
  align-self: stretch;
}



.main-page-empty-area {
  display: flex;
  padding: 128px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.main-page-empty-area > svg {
  width: 40px;
  height: 40px;
}

.main-page-empty-area h6 {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 330px;
  margin-bottom: 0px;
}

.main-page-empty-area p {
  color: var(--Performa-Black, #102040);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main-page-empty-area button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.dream-side-modal-form textarea {
  height: 160px;
}

.dream-side-modal-form label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.dream-side-modal-form small {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  display: block;
  margin-bottom: 4px;
}

.dream-side-modal-form .description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.funnel-create-area {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.funnel-create-area .inputs-area {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.funnel-create-area h4 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
  margin-bottom: 0px;
}

.funnel-create-area p {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.funnel-create-area .banded-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.funnel-create-area .banded-wrapper div:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.analyst .btn-outline-area {
  color: var(--Dark-Red);
}

.funnel .btn-outline-area {
  color: var(--Dark-Blue);
}

.btn-outline-danger {
  color: var(--Dark-Red);
  border-color: var(--Dark-Red);
}

.btn-outline-area {
  border-radius: 8px;
  border: 1px solid currentColor;
}

.funnel-create-area .use-case-area > div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.funnel-create-area .btn-outline-area {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.funnel-creation-form .add-new-step .stage-description {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}


.stage-additional-options .options-holder {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  margin-top: 24px;
}

.stage-additional-options .options-holder .inner-opt-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
}

.stage-additional-options .options-holder .inner-opt-group label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
}

.stage-additional-options .options-holder .inner-opt-group .form-check-label {
  font-weight: 400;
}

.stage-additional-options .options-holder .inner-opt-group small {
  display: block;
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  margin-top: 0px;
}

.funnel-creation-form .verify-settings {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.btn-link {
  color: var(--Dark-Blue);
}

.btn-outline-primary {
  font-weight: 500;
}

.funnel-creation-form .test-view {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.tests-tab-content {
  border-radius: 12px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tests-tab-content label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.option-middle .long-description {
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.additional-options-select__control {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  padding: 0.5rem 1rem;
  box-shadow: none !important;
}

.additional-options-select__value-container {
  padding: 0px !important;
}

.info-expander-section {
  margin-top: 32px;
}

.funnel-view-page .buttons-area button {
  display: flex;
  gap: 12px;
  align-items: center;
}

.page-inner-wrapper  .page-header-area .breadcrumbs a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  text-decoration: none;
}



.breadcrumbs .set a {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dream-table-wrapper .rdt_Pagination,
.dream-table-wrapper .rdt_Table,
.dream-table-wrapper .rdt_TableHeadRow,
.dream-table-wrapper .rdt_TableRow {
  background: transparent;
}

.dream-table-wrapper .rdt_TableHeadRow {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

/* This is the little annoying cursor */
.dream-table-wrapper .rdt_TableHeadRow .rdt_TableCol_Sortable span {
  color: var(--Wallstreet-Black, #000d26);
  font-size: 8px;
  margin-left: 6px;
  margin-top: 2px;
}

.dream-table-wrapper .rdt_TableCell {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.dream-table-wrapper .rdt_Pagination {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.dream-table-wrapper .linkedin-icon svg {
  width: 17.5px;
  height: 20px;
  color: var(--Dark-Blue);
}

.selected-actions {
  display: flex;
  justify-content: flex-end;
}

.selected-actions button {
  width: 160px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  justify-content: center;
}

.test-action-button {
  width: 100%;
}

.test-action-button > button {
  width: 100%;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.test-action-button > button svg,
.test-action-button > button > div {
  color: var(--Light-Blue);
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.test-action-button button.in-progress svg {
  animation: spin 1s linear infinite;
}

// .embedded-agent-area {
//   width: 300px;
//   height: 440px;
//   padding: 12px;
//   border-radius: 12px;
//   border: 1px solid var(--Grays-Lighter, #e6e8eb);
//   background: var(--Lisa-Yellow, #f0eddc);
//   overflow: auto;
// }

// .narratives-section {
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8px;
//   align-self: stretch;
//   display: flex;
// }

// .narrative-entry {
//   display: flex;
//   padding: 12px;
//   flex-direction: column;
//   align-items: flex-start;
//   align-self: stretch;
//   border-radius: 8px;
//   border: 1px solid var(--Grays-Light, #c1c5cd);
//   background: #fff;

//   color: var(--Wallstreet-Black, #000d26);
//   font-family: 'Spline Sans';
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 24px; /* 171.429% */
//   word-break: break-word;
// }

.narrative-timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}

.modal-dialog {
}
.modal-content {
  display: flex;
  width: 480px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  background: var(--Plus-White, #fffefa);
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: none;
  padding: 0px;
}

.modal-header .h4 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.modal-footer {
  padding: 0px;
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.modal-footer > * {
  margin: 0px;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-self: stretch;
  border-bottom: none;
  flex-direction: column;
  padding: 0px;
}

.search-step-matches-view {
  font-size: 24px;
  margin-top: 24px;
}

.stage-additional-options .options-holder .inner-opt-group.csv-uploader {
  display: flex;
  gap: 24px;
}

.stage-additional-options .options-holder .inner-opt-group.csv-uploader input {
  margin-top: 4px;
}

.org-syncing-spinner {
  color: var(--Light-Blue);
}

.import-tag {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Grays-Lighter, #e6e8eb);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exclusion-tag {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(246, 126, 125, 0.15) 0%, rgba(246, 126, 125, 0.15) 100%), #FFF;color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inner-opt-group .contextual-description {
  margin-top: 24px;
}

.dream-table-loader {
  padding: 24px;
  background: var(--Lisa-Yellow);
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}

.dream-table-wrapper.loading {
  margin-top: 12px;
}

.page-header-area .links-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.page-header-area .links-area a {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: none;
}

.page-header-area .image-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.page-header-area .image-wrapper img {
  width: 40px;
  height: 40px;
  border-radius: 64px;
}

.page-header-area .image-wrapper {
  width: 700px;
}

.page-header-area .image-wrapper .title-and-text {
  width: auto;
}

.add-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.add-button *:not(svg) {
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input[type='range'].range-slider::-webkit-slider-runnable-track {
  border-color: var(--Light-Blue) !important;
  background: var(--Light-Blue) !important;
  height: 4px !important;
}

input[type='range'].range-slider::-webkit-slider-thumb {
  background: var(--Plus-White) !important;
  width: 36px;
  height: 36px;
  margin-top: -16px;
  border: 8px solid var(--Dark-Blue) !important;
  box-shadow: 0px 2px 8px rgba(0, 13, 38, 0.2) !important;
}

.indicator-line {
  display: flex;
  justify-content: space-between;
}

.indicator-line span:first-child {
  padding-left: 16px;
  padding-right: 16px;
}

.indicator-line span:last-child {
  padding-right: 8px;
}

.modal-footer button {
  display: flex;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}


.dream-tabs.large .nav-link {
  font-size: 16px;
  line-height: 24px; /* 150% */
}


.dream-top-boxes {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.dream-top-boxes .top-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #E6E8EB);
  background: #FFF;
}

.dream-top-boxes .top-box.loading {
  height: 132px;
  background: var(--Lisa-Yellow, #F7F5E9);
  border-color: var(--Lisa-Yellow, #F7F5E9);
}

.dream-top-boxes .top-box.loading * {
  visibility: hidden;
}

.dream-top-boxes.bolder .top-box h5 {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 142.857% */
}

.dream-top-boxes .top-box h5 {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}


.dream-top-boxes svg {
  width: 24px;
  height: 24px;
  color: var(--Dark-Red);
}
.dream-top-boxes .top-box span {
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background: var(--Grays-Lighter, #E6E8EB);
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.dream-top-boxes .top-box p {
  color: var(--Dark-Blue, #086788);
  font-family: "Spline Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 112.5% */
    display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.campaign-step-wrapper .campaign-overview {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
}

.campaign-step-wrapper .campaign-overview .nav-link {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.alert-primary {
  border: 1px solid var(--Grays-Medium, #838B9B);
  background: var(--Lisa-Yellow, #F0EDDC);
}

.alert-info .spinner-border {
  color: var(--Dark-Blue); 
}

.page-header-area .links-area span {
  color: var(--Performa-Black, #152647);
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}

.small-reminder-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  align-self: stretch;
}

.small-reminder {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 3px;

  border-radius: 24px;
  background: var(--Lisa-Yellow, #F0EDDC);
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.small-reminder button {
  font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
text-decoration: none;
}

.task-list.empty-state p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.test-step-breadcrumbs button {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: var(--Dark-Blue, #086788);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
}

.test-form-inputs-wrapper {
  margin: 12px 0px;
}

.setup-prompts-button.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000D26);
  background: var(--Dream-Yellow, #FFD601);
}

.setup-prompts-button.active svg {
  color: var(--Wallstreet-Black);
}

.setup-prompts-button {
  display: flex;
  padding: 12px 24px 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  background: #FFF;
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  cursor: pointer;
}

.samples-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 8px;
}

.samples-holder .sample {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;

  border-radius: 32px;
  background: var(--Grays-Lightest, #F3F3F5);
  color: #000;
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.select-as-radios-wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.select-as-radios-wrapper label {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}


.notifications-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.notifications-wrapper .notifications-line {
  position: absolute;
  width: 1px;
  left: 10px;
  border-left: 1px dashed var(--Dark-Red);
}

.notifications-wrapper .notification {
  display: flex;
  align-items: flex-start;
  gap: 13px;
  width: 100%;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.notifications-wrapper .notifications-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.notifications-wrapper .icon-holder {
  background: var(--Plus-White);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.notifications-wrapper .icon-holder svg {
  background: transparent;
  width: 20px;
  height: 20px;
  color: var(--Dark-Red);
}

.rdt_Table img {
  width: 32px;
  height: 32px;
  border-radius: 26px;
}
.rdt_Table .fake-pic,
.rdt_Table .fake-pic > svg,
.rdt_Table .fake-pic .org-fake {
  width: 32px;
  height: 32px;
}

.org-fake {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 32px;
  background: linear-gradient(0deg, #C1C5CD 0%, #E6E8EB 100%);
  box-shadow: 0px -1px 4px 0px rgba(0, 13, 38, 0.10) inset;
}

.btn-outline-primary:hover {
  background: transparent;
  color: var(--Wallstreet-Black);
}
.btn-outline-primary:active {
  background: transparent;
  color: var(--Wallstreet-Black);
}

.alert-dream {
  color: #000;
font-family: "Spline Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
  border-radius: 8px;
border: 1px solid var(--Grays-Medium, #838B9B);
background: var(--Lisa-Yellow, #F7F5E9);
}

.title-and-text h3 small {
  color: var(--Performa-Black, #152647);
font-family: "Spline Sans";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px;
}

.title-and-text b {
  font-weight: 500;
}

.nav-task-count-tag {
  display: flex;
  width: 26px;
  height: 20px;
  padding-top: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 32px;
  background: var(--Light-Red, #F67E7D);
  color: #000;
font-family: "Spline Sans";
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 183.333% */
user-select: none;
}

.task-list-outer-wrapper .nav-task-count-tag {
  display: flex;
  width: 36px;
  height: 28px;
  padding-top: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 32px;
  background: var(--Light-Red, #f67e7d);
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}
.nav-task-count-tag .spinner-border {
  width: 10px;
  height: 10px;
}

.dash-link .tag-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.jobtitle-help {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.jobtitle-help button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.jobtitle-help button svg  {
  width: 20px;
  height: 20px;
  color: #5C6880;
}

.jobtitle-help button .spinner-border {
  height: 20px;
  width: 20px;
}

.jobtitle-help .additional-options-select__control {
  max-height: 150px;
  overflow: scroll;
  align-items: flex-start;
}

.jobtitle-help .additional-options-select__indicators {
  align-items: flex-start;
}
.full-line {
  flex: 1;
}


.toast-container-wrapper {
  width: 440px;
}

.feed-profile-image {
  transition: opacity 0.5s ease-in-out;
}

.feed-profile-image.loading {
  opacity: 0;
}

.feed-profile-image.loaded {
  opacity: 1;
}

.big-error-banner {
  display: flex;
  height: 40px;
  padding: 12px 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border: 1px solid var(--Light-Red, #F67E7D);
  background: var(--Light-Red, #F67E7D);
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.big-error-banner svg {
  width: 24px;
  height: 24px;
  opacity: 0.75;
}

.dropdown-menu {
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000D26); 
}

.dropdown-item {
  color: var(--Plus-White, #FFFEFC);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.dropdown-item:hover {
  background: var(--Wallstreet-Black, #000D26); 
  color: var(--Plus-White, #FFFEFC);
}
.dropdown-item a {
  color: var(--Plus-White, #FFFEFC);
  text-decoration: none;
}

.ag-watermark {
  display: none !important;
}
.confirm-modal-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.confirm-modal-button-wrapper.vertical {
  flex-direction: column;
  gap: 24px;
}

.confirm-modal-button-wrapper.little-sweetie {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.confirm-modal-button-wrapper.little-sweetie button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5C6880);
  background: var(--White, #FFF);
  color: var(--Pomona-Black, #5C6880);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.20);

  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.copy-clicker {
  cursor: pointer;
}

.click-copy-tooltip {
  min-width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.click-copy-tooltip * {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-empty {
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }

  .dashboard-empty-icon {
    width: 64px;
    height: 64px;
  }

  .dashboard-error-icon {
    width: 32px;
    height: 32px;
    color: #5C6880;
  }

  .dashboard-empty-title {
    color: var(--Wallstreet-Black, #000D26);
    font-family: "Spline Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    text-align: center;
  }

  .dashboard-empty-text {
    color: var(--Performa-Black, #152647);
    text-align: center;
    font-family: "Spline Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}