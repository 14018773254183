.sdrs-index {
  width: 100%;
}

.sdrs-index .page-header-area .buttons-area button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.sdrs button svg {
  color: var(--Light-Blue);
}

.internal-profile {
  display: flex;
  width: 300px;
  min-height: 373px;
  padding: 16px 0px 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
  box-shadow:
    0px 8px 12px 0px rgba(16, 32, 64, 0.1),
    0px 1px 3px 0px rgba(0, 13, 38, 0.15);
  position: relative;
}

.internal-profiles {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.internal-profiles-list {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

.internal-profile:not(.empty) .backer {
  width: 300px;
  height: 96px;
  position: absolute;
  background: var(
    --SDRs-Gradient,
    linear-gradient(131deg, #64b6ac 0.93%, #77d9cd 102.14%)
  );
  z-index: 0;
  top: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.internal-profile.empty .backer {
  width: 300px;
  height: 64px;
  position: absolute;
  background: var(--Grays-Lighter, #e6e8eb);
  top: 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 0;
}

.internal-profile .dotter {
  width: 40px;
  height: 8px;
  flex-shrink: 0;
  margin-top: 16px;
  margin-left: calc(50% - 20px);
  border-radius: 24px;
  background: var(--Performa-Black, #152647);
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.internal-profile .content-area {
  position: relative;
  z-index: 0;
  display: flex;
  padding: 20px 24px 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}

.internal-profile .image-wrapper img {
  width: 128px;
  height: 128px;
  border-radius: 8px;
}

.internal-profile .image-wrapper {
  display: flex;
  width: 128px;
  height: 128px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Grays-Medium, #838b9b);
  color: var(--Plus-White);
}

.internal-profile h5 {
  color: #000;
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.internal-profile p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.internal-profile .button-area {
  display: flex;
  width: 196px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.internal-profile .button-area button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dream-side-modal-form.add-profile {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.internal-profile .dual-options-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-top: 1px solid rgba(227, 229, 233, 1);
  width: 100%;
}

.internal-profile .dual-options-area button {
  display: flex;
  padding: 8px 0px;
  padding-top: 12px;
  margin-top: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.internal-profile .dual-options-area button:first-child {
  border-right: 1px solid rgba(227, 229, 233, 1);
}

.internal-profile .dual-options-area button {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.silly-design-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex: 1;
}

.internal-profile .dual-options-area button.remove svg {
  color: var(--Light-Red);
}

.profile-view {
  width: 100%;
}

.lower-profile-area {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.internal-profile .extra-info-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.internal-profile .extra-info-section .set {
  display: flex;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.internal-profile .extra-info-section .set .label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.internal-profile .extra-info-section .set .value {
  color: var(--Grays-Medium, #838b9b);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  width: 100px;
}

.lower-profile-area .right-area {
  flex: 1;
}

.profile-view .tab-wrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.profile-view .tab-wrapper .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.profile-view .tab-wrapper h5 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
}

.profile-view .tab-wrapper .header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.profile-view .buttons-row {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 24px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.profile-view .buttons-row button {
  display: flex;
  padding: 12px 20px 12px 16px;
  align-items: center;
  gap: 12px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.profile-view .biography-wrapper {
  display: flex;
  padding: 24px;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.profile-view .biography-area,
.profile-view .biography-area textarea {
  width: 100%;
  height: 600px;
}

.profile-view .save-button-area {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.interview-intro-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.interview-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
}

.interview-section button {
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.profile-warning {
  margin-bottom: 16px;
}

.profile-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
}

.profile-view .save-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.profile-view .save-button-wrapper button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.small-number-entry {
  width: 100px;
}

.profile-settings .day-selector {
  display: flex;
  align-items: center;
  gap: -1px;
  border-radius: 16px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
  max-width: 700px;
  margin-top: 8px;
}

.profile-settings .day-selector .day:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-left: none;
}

.profile-settings .day-selector .day:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.profile-settings .day-selector .day {
  display: flex;
  flex: 1;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  border-left: 1px solid var(--Grays-Lighter, #e6e8eb);
  cursor: pointer;
}

.profile-settings .day-selector .day svg {
  width: 24px;
  height: 24px;
}

.profile-settings .day-selector .day:not(.active) svg {
  color: #e6e8eb;
}

.profile-settings .day-selector .day.active {
  background: var(--Dream-Yellow, #ffd601);
}
.profile-settings .day-selector .day:not(:first-child).active {
  border-left: 1px solid var(--Grays-Light, #c1c5cd);
}

.profile-settings .sending-times .time-selector {
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-settings .sending-times .time-selector input {
  width: 130px;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.profile-settings .sending-times .time-selector .timezone-select__control {
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.profile-settings .rsw-editor {
  min-height: 264px;
  padding: 12px 0px;
  margin-top: 8px;
  overflow-y: auto;
}

.profile-settings .rsw-toolbar {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background: #fff;
  border-bottom: 1px solid var(--Lighter-Grey);
}

.profile-settings .rsw-toolbar > *:last-child {
  flex: 1;
}

.profile-settings .rsw-ce {
  padding: 12px;
}
.profile-settings .rsw-ce a {
  color: revert;
}
.profile-settings .rsw-ce:focus {
  outline: none;
}

.profile-settings .rsw-toolbar button {
  width: 24px;
  height: 24px;
}

.profile-settings .rsw-toolbar button svg {
  color: var(--Wallstreet-Black);
  width: 20px;
  height: 20px;
}

.profile-settings .nice-lines {
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 0px;
}
.profile-settings .nice-line {
  border-radius: 12px;
  height: 20px;
  width: 300px;
  background: var(--Very-Light-Grey);
}

.profile-settings .right-set {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.test-signature-button {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: auto !important;
  height: auto !important;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
}

.sig-confirm-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sig-confirm-modal p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.sig-confirm-modal input {
  display: flex;
  height: 48px;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}
