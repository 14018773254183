.editable-field {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.editable-field-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.editable-field-header h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
}

.editable-field-header .title-and-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.editable-field-header .title-and-text small {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0px;
}

.btn-outline-link {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Dark-Blue, #086788);
  color: var(--Dark-Blue, #086788);
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.phantom-highlighted {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.phantom-highlighted button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefa);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
