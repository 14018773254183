.dream-loading-table {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 204px);
}
.dream-loading-table .grid-container {
  display: grid;
  grid-template-columns: repeat(9, 200px); /* 3 columns each 100px wide */
  grid-template-rows: repeat(30, 48px); /* 3 rows each 100px high */
  background-color: #e6e8eb;
  grid-gap: 1px;
  flex: 1;
  overflow: hidden;
}

.dream-loading-table .header {
  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Grays-Lightest, #f3f3f5);
  height: 36px;
}

.dream-loading-table .grid-item {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #fff;
}

.loading-signal {
  position: absolute;
  width: 200px;
  left: calc(50% - 224px + 114px);
  top: calc(50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-signal .inner-wrapper {
  display: flex;
  padding: 4px 12px 4px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 32px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Plus-White, #fffefc);

  /* Modal shadow */
  box-shadow:
    0px 8px 16px 0px rgba(6, 22, 54, 0.1),
    0px 2px 4px 0px rgba(0, 13, 38, 0.2);

  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.loading-signal .inner-wrapper .spinner-border {
  color: var(--Light-Blue);
  width: 24px;
  height: 24px;
}
