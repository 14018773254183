.dream-side-modal-loader {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.dream-side-modal-loader p {
  color: #000;
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 112.5% */
}

.dream-side-modal-loader-icon {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #086788 90deg, #0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: l18 1s infinite;
}
@keyframes l18 {
  0% {
    background-position:
      0 0,
      10px 10px,
      20px 20px;
  }
  33% {
    background-position:
      -30px 0,
      10px 10px,
      20px 20px;
  }
  66% {
    background-position:
      -30px 0,
      10px 40px,
      20px 20px;
  }
  100% {
    background-position:
      -30px 0,
      10px 40px,
      50px 20px;
  }
}
