.campaign-overview-stats-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.campaign-overview-stats-outer-wrapper small {
  flex: 1 0 0;
  width: 100%;
  display: block;
  color: var(--Pomona-Black, #5c6880);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.campaign-overview-stats {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.campaign-overview-stats .entry {
  display: flex;
  padding: 16px 16px 0px 16px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.campaign-overview-stats .heading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 180px;
}

.campaign-overview-stats .heading h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.campaign-overview-stats .heading h4 {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}

.campaign-overview-stats .chart-holder {
  flex: 1;
  height: 128px;
  padding: 20px 0px;
}

.fake-entry {
  display: flex;
  height: 144px;
  padding: 16px 16px 0px 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: var(--Lisa-Yellow, #f7f5e9);
  flex: 1;
}

.campaigns-list {
  width: 100%;
}

.campaigns-list .center-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.campaigns-list .center-spinner > * {
  color: var(--Light-Blue);
}
