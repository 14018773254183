.not-found-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.not-found-page svg {
  color: #f67e7d;
  width: 48px;
  height: 48px;
}

.not-found-page h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.not-found-page p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.not-found-page button {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
