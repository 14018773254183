.snapshots {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.snapshots .page-header-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.snapshots h3 {
  color: var(--Wallstreet-Black);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.snapshots .title-and-text p {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.snapshot-sections {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.snapshot-section {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White);
}

.snapshot-section:hover {
  border-color: var(--Dark-Blue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-label {
  color: var(--Wallstreet-Black);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.percentage-indicator {
  color: var(--Dark-Grey);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.company-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.company-logo-wrapper {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  position: relative;
}

.company-logo-wrapper:hover {
  transform: scale(1.05);
}

.company-logo-wrapper:focus {
  outline: 2px solid var(--Dark-Blue);
  border-radius: 50%;
}

.company-logo-wrapper:focus:not(:focus-visible) {
  outline: none;
}

.company-logos .fake-pic {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Lisa-Yellow);
}

.company-logos .feed-profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.remaining-count {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 6px;
  background: var(--Lisa-Yellow);
  color: var(--Performa-Black);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 48px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White);
}

.loading-state .spinner-border {
  color: var(--Dark-Grey);
  width: 24px;
  height: 24px;
}

.loading-state p {
  color: var(--Dark-Grey);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 48px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White);
}

.error-state svg {
  width: 24px;
  height: 24px;
  color: var(--Dark-Grey);
}

.error-state p {
  color: var(--Dark-Grey);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 0;
}

.campaign-selector {
  margin-bottom: 24px;
  max-width: 400px;
}

.campaign-selector .form-label {
  color: var(--Wallstreet-Black);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.campaign-selector .form-select {
  display: flex;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White);
  color: var(--Wallstreet-Black);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.campaign-selector .form-select:hover {
  border-color: var(--Dark-Blue);
}

.campaign-selector .form-select:focus {
  border-color: var(--Dark-Blue);
  box-shadow: none;
  outline: none;
}

.campaign-selector,
.snapshot-content {
  padding: 0px 24px;
}

.tooltip {
  position: absolute;
  background-color: var(--Plus-White);
  border: 1px solid var(--Grays-Light, #c1c5cd);
  padding: 8px;
  border-radius: 8px;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--Wallstreet-Black);
  z-index: 1;
}

.tooltip::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--Grays-Light, #c1c5cd) transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--Plus-White) transparent;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
}
