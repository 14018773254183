.campaign-sales-team {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.campaign-sales-team .cst-set {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.campaign-sales-team .cst-set > h6 {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-sales-team .cst-set h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.campaign-sales-team .cst-set p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.campaign-sales-team .cst-set .cst-profiles-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
}

.campaign-sales-team .cst-set .cst-profiles-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.cst-profiles-wrapper .button-wrapper button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cst-profiles-wrapper .button-wrapper button svg {
  color: var(--Wallstreet-Black);
}

.cst-set .loading-state {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Lisa-Yellow, #f0eddc);
}

.cst-set .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.cst-set .empty svg {
  height: 40px;
  width: 40px;
}

.ast-modal > p:first-child {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 12px;
}

.ast-modal .ast-profiles-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.ast-modal .ast-profiles-wrapper .profile {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefa);
}

.cst-profiles-wrapper .profile img,
.ast-modal .ast-profiles-wrapper .profile img {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
}

.ast-modal .ast-profiles-wrapper .profile .left-side {
  display: flex;
  display: flex;
  align-items: center;
  gap: 16px;
}

.ast-modal .profile button {
  display: flex;
  height: 40px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ast-modal .pseudo-pic,
.cst-profiles-list .profile .pseudo-pic {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--Grays-Medium, #838b9b);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cst-profiles-list .profile .pseudo-pic > svg,
.ast-modal .pseudo-pic > svg {
  color: white;
  height: 24px;
  width: 24px;
}
.ast-modal .profile.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.cst-profiles-wrapper .profile {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
}

.cst-profiles-wrapper .profile .left-side {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.cst-profiles-wrapper .profile h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.cst-profiles-wrapper .profile p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  word-break: break-all;
}

.cst-profiles-wrapper .add-button-wrapper {
  align-self: stretch;
}

.cst-profiles-wrapper .add-button-wrapper button {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: var(--Plus-White, #fffefc);
}

.cst-profiles-wrapper .remove-button-wrapper button {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--Plus-White, #fffefc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
