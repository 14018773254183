.linked-accounts-outer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.linked-accounts-outer-wrapper > .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.linked-accounts-outer-wrapper .empty-state > svg {
  width: 40px;
  height: 40px;
  color: var(--Dream-Yellow);
}

.linked-accounts-outer-wrapper .empty-state button,
.linked-accounts-outer-wrapper .empty-state .button-area {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.account-linking-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.account-linking-modal-wrapper > p:first-child {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account-type-select {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.account-type-select .account-type,
.scope-options-outer-wrapper .scope-options .scope-option {
  display: flex;
  height: 132px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  background: var(--Lisa-Yellow, #F0EDDC);
  cursor: pointer;
}

.account-type-select .account-type.selected {
border: 1px solid var(--Wallstreet-Black, #000D26);
background: var(--Dream-Yellow, #FFD601);
}

.account-type-select img {
  width: 48px;
  height: 48px;
}

.account-type-select span {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.scope-options-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.scope-options-outer-wrapper .header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.scope-options-outer-wrapper .header p {
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scope-options-outer-wrapper .scope-options {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.scope-options .scope-option .icon-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.scope-options .scope-option svg:first-of-type,
.scope-options .scope-option svg:last-of-type
 {
  color: var(--Dark-Blue);
}

.scope-options .scope-option.selected {
  border-radius: 12px;
  border: 1px solid var(--Wallstreet-Black, #000D26);
  background: var(--Dream-Yellow, #FFD601);
}


.linked-accounts-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  background: #FFF;
}

.linked-accounts-wrapper img {
  width: 32px;
}

.linked-accounts-wrapper .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.linked-accounts-wrapper .header h4 {
  color: #000;
  font-family: "Spline Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.linked-accounts-wrapper .header button {
  display: flex;
  width: 255px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Plus-White, #FFFEFA);
  font-family: "Spline Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.linked-accounts-wrapper .table-area {
  width: 100%;
}

.linked-accounts-wrapper table {
  width: 100%;
}

.linked-accounts-wrapper table thead th {
  padding: 16px;
  padding-bottom: 12px;
  padding-top: 0px;
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.linked-accounts-wrapper table tbody {
  border-radius: 8px;
}

.linked-accounts-wrapper table tbody tr {
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #C1C5CD);
  background: var(--Plus-White, #FFFEFA);
  overflow: hidden;
}

.linked-accounts-wrapper table tbody td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.linked-accounts-wrapper table tbody td {
  padding: 16px;
  color: var(--Wallstreet-Black, #000D26);
  font-family: "Spline Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

/* .linked-accounts-wrapper table tbody td:last-child {
  text-align: right;
} */

.linked-accounts-wrapper table tbody td:last-child svg {
  color: var(--Dark-Red);
}

.double-logo {
  display: flex;
  align-items: center;
  gap: 16px;
}