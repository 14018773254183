.whatsapp-qr-wrapper {
  text-align: center;
}

.whatsapp-qr-wrapper .loading-state {
  padding: 2rem;
}

.whatsapp-qr-wrapper .loading-message {
  margin-top: 1rem;
}

.whatsapp-qr-wrapper .error-state {
  padding: 2rem;
  color: #dc3545;
}

.whatsapp-qr-wrapper {
  width: 100%;
}

.whatsapp-qr-wrapper .qr-code-container,
.whatsapp-qr-wrapper .loading-state {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f0eddc);
  cursor: pointer;
  width: 100%;
}

.whatsapp-qr-wrapper .expiry-message {
  color: #6c757d;
}
