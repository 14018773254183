.engagement-activities {
  p {
    color: var(--Performa-Black, #152647);
    font-family: "Spline Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .activities-list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: -1px;

    border-radius: 12px;
    border: 1px solid var(--Grays-Light, #C1C5CD);
    background: #FFF;

    .activity-item {
      
      padding: 12px 16px;
      gap: 8px;
      align-self: stretch;
      border-bottom: 1px solid var(--Grays-Lighter, #E6E8EB);
      color: var(--Pomona-Black, #5C6880);
      font-family: "Spline Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      
      display: flex;
      flex-direction: column;
      gap: 4px;

      .top-set {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .prospect-name {
          width: 128px;
        }
        .activity-time {
          width: 140px;
        }
      }

      .activity-content {

        svg {
          width: 20px;
          height: 20px;
        }
        color: var(--Wallstreet-Black, #000D26);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        flex: 1 0 0;
      }

      .activity-detail {
        word-break: break-all;
      }
    }
  }
}