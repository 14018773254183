.campaign-view .buttons-area.autopilot {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-view .buttons-area.autopilot svg {
  color: var(--Dream-Yellow);
}

.campaign-view .title-and-text b {
  font-weight: 500;
}

.campaign-view-lower-area {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  padding-top: 0px;
  gap: 16px;
}

.campaign-view-lower-area .top-box p {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.campaign-view-lower-area .top-box p b {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
}

.campaign-view-lower-area .top-box p small {
  display: block;
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.campaign-view-lower-area .actions-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.campaign-view-lower-area .actions-row a {
  text-decoration: none;
}

.campaign-view-lower-area .actions-row .buttons-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.campaign-view-lower-area .actions-row .settings-right > a > div {
  display: flex;
  padding: 0px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.campaign-view-lower-area .actions-row .settings-right > a > div svg {
  color: #838b9b;
}

.campaign-view-lower-area .actions-row button {
  display: flex;
  padding: 8px 24px 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.campaign-view-lower-area .actions-row button svg {
  color: var(--Light-Blue);
}

.campaign-view-lower-area .actions-row svg {
  height: 20px;
  width: 20px;
  color: var(--Light-Blue);
}

.campaign-view-lower-area .main-area {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}

.campaign-view-lower-area .main-area .left-side-wrapper {
  flex: 1;
}

.campaign-view-lower-area .camp-right-side-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.campaign-view-lower-area .camp-right-side-wrapper,
.campaign-view-lower-area .task-list-wrapper {
  width: 360px;
}

.campaign-view.first-loading .top-box {
  background: var(--Lisa-Yellow);
  border: var(--Lisa-Yellow);
}

.campaign-view.first-loading .top-box * {
  visibility: hidden;
}

.campaign-view.first-loading .actions-row button {
  background: var(--Lisa-Yellow);
  border: var(--Lisa-Yellow);
  color: var(--Lisa-Yellow);
  box-shadow: none;
  height: 38px;
}
.campaign-view.first-loading .actions-row button svg {
  color: var(--Lisa-Yellow);
}

.prospects-lower-area.embedded {
  padding-left: 0px;
  padding-right: 0px;
}

.prospects-lower-area {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.prospects-lower-area .logo-name {
  display: flex;
  width: 232px;
  align-items: center;
  gap: 12px;
}

.prospects-lower-area .logo-name .fake-pic svg {
  width: 32px;
  height: 32px;
}

.prospects-lower-area .logo-name img {
  width: 32px;
  height: 32px;
  border-radius: 26px;
}
.prospects-lower-area .logo-name {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.dream-positive {
  background: linear-gradient(
      0deg,
      rgba(255, 214, 1, 0.2) 0%,
      rgba(255, 214, 1, 0.2) 100%
    ),
    #fff;
}

.processing-alert-wrapper {
  margin-bottom: 16px;
  margin-top: 6px;
  transition:
    opacity 0.5s ease-in-out,
    height 0.5s ease-in-out,
    margin 0.5s ease-in-out;
}

.processing-alert-wrapper.hidden {
  opacity: 0;
  height: 0px;
  margin: 0px;
}
.processing-alert-wrapper.visible {
  opacity: 1;
  height: 38px;
  margin-top: 16px;
  margin-bottom: 6px;
}

.campaign-view .main-area .left-side-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.burndown-wrapper {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.burndown-wrapper.loading {
  background: var(--Lisa-Yellow);
  border: var(--Lisa-Yellow);
  color: var(--Lisa-Yellow);
  box-shadow: none;
  height: 312px;
}

.burndown-wrapper.loading * {
  visibility: hidden;
}

.burndown-wrapper h4 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.burndown-wrapper .key {
  display: flex;
  align-items: center;
  gap: 24px;
}

.burndown-wrapper .key-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.burndown-wrapper .key-item .bauble {
  width: 11px;
  height: 11px;
  border-radius: 36px;
}

.initial .bauble {
  background: var(--Light-Blue, #07a0c3);
}

.follow-up .bauble {
  background: #64b6ac;
}

.unapproved_user .bauble {
  background: var(--Dream-Yellow);
}

.unapproved_chain .bauble {
  background: rgba(255, 208, 4, 0.2);
}

.burndown-wrapper .key-item p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first-loading .campaign-position-wrapper {
  background: var(--Lisa-Yellow);
  border-radius: 8px;
  width: 360px;
  min-height: 200px;
}

.first-loading .campaign-position-wrapper * {
  visibility: hidden;
}

.campaign-position {
  display: flex;
  flex-direction: column;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
  width: 360px;
}

.campaign-position h6 {
  display: flex;
  padding: 12px 12px 12px 16px;
  align-items: center;
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-position .header-row {
  display: flex;
  padding: 12px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.campaign-position .header-row span {
  flex: 1 0 0;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.campaign-position .sequence-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -1px;
  align-self: stretch;
}

.campaign-position .sequence-row {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-top: 1px solid var(--Grays-Light, #c1c5cd);
}

.campaign-position .sequence-row span {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.campaign-position .sequence-row span svg {
  color: var(--Light-Blue, #07a0c3);
  width: 24px;
  height: 24px;
}

.campaign-position .footer-link {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: rgba(7, 160, 195, 0.05);
}

.campaign-position .footer-link a {
  color: var(--Dark-Blue, #086788);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-decoration: none;
}

.campaign-position .sequence-row.positive {
  background: rgba(100, 182, 172, 0.05);
}

.campaign-position .sequence-row.negative {
  background: rgba(246, 126, 125, 0.05);
}

.campaign-position .sequence-row.positive svg {
  color: var(--Light-Green, ##64b6ac);
}

.campaign-position .sequence-row.negative svg {
  color: var(--Dark-Red, #931f1d);
}

.prospect-step-wrapper,
.companies-step-wrapper {
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  background: rgba(243, 243, 245, 0.5);
  flex: 1;
  overflow: hidden;
  align-items: stretch;
}

.campaign-edit-prospects,
.campaign-edit-companies {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.campaign-edit-prospects .lower-area,
.campaign-edit-companies .lower-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.prospect-step-wrapper .left-side,
.companies-step-wrapper .left-side {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-self: stretch;
  border-right: 1px solid var(--Grays-Lighter, #e6e8eb);
}
.companies-step-wrapper .left-side .ss-outer,
.prospect-step-wrapper .left-side .ps-outer {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 24px;
}

.big-sync-button-area {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 72px;
  border-top: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Plus-White, #fffefc);
}

.big-sync-button-area button {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inner-sync.syncing,
.big-sync-button.syncing {
  border-radius: 8px;
  border-color: var(--Grays-Light, #c1c5cd);
  background: var(--Grays-Light, #c1c5cd);
  color: var(--Wallstreet-Black);
  font-weight: 500;
  opacity: 1 !important;
}

.inner-sync .spinner-border,
.big-sync-button-area button .spinner-border {
  color: var(--Light-Blue);
  width: 20px;
  height: 20px;
}

.companies-step-wrapper .right-side,
.companies-step-wrapper .ocl-wrapper,
.prospect-step-wrapper .right-side,
.prospect-list-page,
.prospect-list-page .page-inner-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.prospect-step-wrapper .right-side .prospect-list-page .prospect-batches {
  overflow-y: scroll;
}

.known-job-title-modal .tab-lower > * {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  gap: 24px;
}

.known-job-title-modal .tab-lower .form-check-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.then-look-for {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prospect-list-page .header {
  display: flex;
  height: 72px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Lighter, #e6e8eb);
}

.prospect-list-page .header b {
  font-weight: 500;
}

.prospect-list-page .header {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.prospect-list-page .header button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
}

.ocl-wrapper .processing-set,
.prospect-list-page .processing-set {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.ocl-wrapper .processing-set .spinner-border,
.prospect-list-page .processing-set .spinner-border {
  width: 24px;
  height: 24px;
  color: var(--Light-Blue);
}

.ocl-wrapper .nice-progress-bar,
.prospect-list-page .nice-progress-bar {
  display: flex;
  height: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--Grays-Lighter, #e6e8eb);
  position: relative;
}

.ocl-wrapper .nice-progress-bar .filler,
.prospect-list-page .nice-progress-bar .filler {
  background: var(--Light-Blue, #07a0c3);
  position: absolute;
  left: 0px;
  height: 8px;
}

.ocl-wrapper .progress-description,
.prospect-list-page .progress-description {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ocl-wrapper .progress-description {
  padding-bottom: 0px;
}

.prospect-batches {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 16px;
}

.prospect-batches .batch {
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  background: #fff;

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.batch.mega-positive {
  border: 1px solid var(--Light-Green, #64b6ac);
}
.batch.mega-positive .dot {
  background: var(--Light-Green);
}

.batch.positive .dot {
  background: var(--Light-Blue, #07a0c3);
}

.batch.negative .dot {
  background: var(--Light-Red, #f67e7d);
}

.batch.neutral .dot {
  background: var(--Grays-Medium, #838b9b);
}

.batch.positive {
  border: 1px solid var(--Light-Blue, #07a0c3);
}

.batch.negative {
  border: 1px solid var(--Light-Red, #f67e7d);
}

.batch.neutral {
  border: 1px solid var(--Grays-Medium, #838b9b);
}

.prospect-batches .batch .batch-title {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
}

.prospect-batches .batch .batch-title .dot {
  width: 12px;
  height: 12px;
  border-radius: 36px;
}

.prospect-batches .batch .batch-title .card-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.prospect-batches .batch .batch-title .card-left p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}

.prospect-batches .batch.expanded .batch-title {
  background: var(--Grays-Lightest, #f3f3f5);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.prospect-batches .prospect-view {
  display: flex;
  padding: 12px 16px;
  gap: 12px;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
}

.prospect-batches .prospect-view:last-child {
  border-bottom: none;
}

.prospect-batches .prospect-view .center-matter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.prospect-batches .prospect-view .logo svg,
.prospect-batches .prospect-view .logo img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.prospect-batches .prospect-view .center-matter .name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.prospect-batches .prospect-view .center-matter .title {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.prospect-batches .prospect-view .center-matter .reasoning {
  color: var(--Pomona-Black, #5c6880);
  font-family: 'Spline Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.prospect-batches .prospect-view .actions-area {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}

.prospect-batches .email-icon-wrapper svg {
  width: 24px;
  height: 24px;
}

.prospect-batches .batch:not(.neutral) .email-icon-wrapper svg {
  color: var(--Light-Green);
}

.prospect-view .status-tag.positive {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: linear-gradient(
      0deg,
      rgba(100, 182, 172, 0.2) 0%,
      rgba(100, 182, 172, 0.2) 100%
    ),
    #fff;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.prospect-view .status-tag.neutral {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: var(--Grays-Lighter, #e6e8eb);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.prospect-view .status-tag.negative {
  background: rgba(246, 126, 125, 0.15);
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.prospect-view .remove-prospect {
  cursor: pointer;
}

.prospect-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 64px;
  text-align: center;
}

.prospect-empty-state h6 {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.prospect-empty-state p {
  color: var(--Performa-Black, #152647);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  width: 460px;
}

.ps-outer .big-add-prospect-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Wallstreet-Black, #000d26);
  color: var(--Plus-White, #fffefc);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.prospect-list-page .missing-companies-line {
  margin: 16px 12px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--Lisa-Yellow, #f7f5e9);

  /* Container-shadow */
  box-shadow: 0px 1px 2px 0px rgba(21, 38, 71, 0.2);
}

.prospect-list-page .inline-error {
  color: var(--Dark-Red);
  text-decoration: underline;
  cursor: pointer;
}

.prospect-list-page
  .missing-companies-line
  + .missing-companies-line.crm-blocked-warning {
  margin-top: -4px;
}

.prospect-list-page .missing-companies-line {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.prospect-list-page .missing-companies-line b {
  font-weight: 500;
}

.prospect-list-page .missing-companies-line > div:first-child {
  display: flex;
  gap: 12px;
}

.prospect-list-page .missing-companies-line .open-missing-modal {
  color: var(--Dark-Blue, #086788);
  text-align: right;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  cursor: pointer;
}

.missing-companies-modal-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
}

.missing-companies-modal-list .company-entry:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.missing-companies-modal-list .company-entry:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.missing-companies-modal-list .company-entry {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  background: #fff;
}

.missing-companies-modal-list .company-entry img,
.missing-companies-modal-list
  .company-entry
  .org-fake
  .missing-companies-modal-list
  .company-entry
  svg {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.ss-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.ss-outer .ss-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.ss-outer .ss-header h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.ss-outer .ss-header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.ss-outer .ss-main-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.fake-batch-padding {
  padding: 20px 12px;
}

.list-builders-wrapper .loading-wrapper {
  display: flex;
  justify-content: center;
}

.prospect-list-page {
  background: var(--Grays-Lightest, #f3f3f5);
}

.prospect-list-page .header h5 {
  font-weight: 400;
}

.actions-area .move-campaign button {
  background: none;
  border: none;
  padding: 4px;
}

.actions-area .move-campaign button:active {
  background: none;
}

.actions-area .move-campaign button svg {
  color: var(--Pomona-Black, #5c6880);
}

.killswitch-engaged {
  color: var(--Wallstreet-Black);
  opacity: 0.5;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
}
.stop-processing-killswitch {
  color: var(--Dark-Blue);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
}
