.warmth-factors {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.warmth-factor {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.warmth-factor svg {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
