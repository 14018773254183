.ps-options-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.ps-options-wrapper .pso-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
}

.ps-options-wrapper .pso-header h6 {
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.ps-options-wrapper .pso-header p {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.current-searches-view {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.current-searches-view .display-cell {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding: 12px 16px;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--White, #fff);
}

.current-searches-view .display-cell > svg {
  width: 24px;
  height: 24px;
  color: var(--Light-Blue);
}

.current-searches-view .display-cell .center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.current-searches-view .display-cell .center p:first-child {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.current-searches-view .display-cell .center p:nth-child(2) {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.current-searches-view .delete-icon svg {
  width: 24px;
  height: 24px;
  color: var(--Pomona-Black);
  cursor: pointer;
}

.ps-outer .add-step-wrapper {
  width: 100%;
}

.ps-outer .add-step-wrapper button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  width: 100%;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: var(--Plus-White, #fffefc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ps-outer .add-step-wrapper button svg {
  color: var(--Light-Blue);
}

.ps-outer .settings-set {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.ps-outer .settings-set h6 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

.ps-outer .settings-set .form-check {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.ps-outer .settings-set .form-check .form-check-label {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.ps-outer .set-guidance-button {
  text-align: left;
  width: 100%;
  font-size: 14px;
  margin-left: 26px;
  margin-top: -5px;
}
