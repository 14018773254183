.step-one-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.step-one-form .use-case-type-selectors {
  display: flex;
  align-items: stretch;
  gap: -1px;
  align-self: stretch;

  border-radius: 16px;
  background: #fff;
}

.step-one-form .use-case-type-selectors > .type {
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border: 1px solid var(--Grays-Light, #c1c5cd);

  cursor: pointer;
}

.step-one-form .use-case-type-selectors > .type:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.step-one-form .use-case-type-selectors > .type:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.step-one-form .use-case-type-selectors > .type:nth-child(2) {
  border-left: none;
  border-right: none;
}

.step-one-form .use-case-type-selectors > .type.active:nth-child(2) {
  border-color: var(--Wallstreet-Black, #000d26);
  border-right-width: 1px;
  border-left-width: 1px;
  border-right-style: solid;
  border-left-style: solid;
}

.use-case-type-selectors {
  margin-top: 4px;
}

.use-case-type-selectors .type.active,
.exclusion-list-button.active {
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.exclusion-list-button.active svg,
.exclusion-list-button.active path,
.use-case-type-selectors .type.active svg,
.use-case-type-selectors .type.active path {
  fill: black;
}

.use-case-type-selectors .type p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-type-outer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.search-type-outer-wrapper h5 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.search-type-outer-wrapper h5 {
  cursor: pointer;
}

.search-type-outer-wrapper h5 svg {
  color: var(--Dark-Blue) !important;
}

.search-type-outer-wrapper .main-sets-section {
  gap: 24px;
}

.search-type-outer-wrapper .category-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid var(--Grays-Light, #c1c5cd);
  padding: 8px 0px;
}

.search-type-outer-wrapper .icon-wrapper {
  height: 32px;
  width: 32px;
}

.search-type-outer-wrapper .icon-wrapper svg {
  width: 32px;
  height: 32px;
}

.search-type-outer-wrapper > * {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.search-type-outer-wrapper .basic-section .search-type-select {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.search-type-select {
  transition:
    max-height 0.5s ease-in-out,
    margin-top 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  margin-top: -8px;
}

.search-type-select.expanded {
  max-height: 500px; /* You could adjust this if you know a better upper bound */
  margin-top: 0;
}

.search-type-select > * {
  display: flex;
  min-width: 262px;
  flex: 1;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  border-radius: 8px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.search-type-select > .option-wrapper svg {
  color: var(--Light-Blue);
}

.search-type-select .option-wrapper {
  cursor: pointer;
}

.search-type-select .option-wrapper .option-name {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.search-type-select .long-description {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.search-type-select .option-wrapper.active {
  border-radius: 8px;
  border: 1px solid var(--Wallstreet-Black, #000d26);
  background: var(--Dream-Yellow, #ffd601);
}

.search-type-select .option-wrapper.active svg {
  color: var(--Wallstree-Black);
}

.options-tab-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.funnel-create-modal .errors-area {
  margin-bottom: 24px;
}

.exclusion-list-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.exclusion-list-area > p {
  align-self: stretch;
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.exclusion-list-button {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: var(--White, #fff);
  cursor: pointer;
}

.exclusion-list-button .button-right p:first-child {
  color: #000;
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.exclusion-list-button .button-right p:last-child {
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.exclusion-list-button .button-right p:last-child b {
  font-weight: 500;
}

.exclusion-list-button svg {
  color: #5c6880 !important;
}

.exclusion-list-button .button-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.header-with-settings {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.header-with-settings .left {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header-with-settings .settings-area {
  display: flex;
  align-items: center;
  gap: 12px;
  display: flex;
  padding: 0px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: var(--Performa-Black, #152647);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.header-with-settings .settings-area:not(.loading) {
  cursor: pointer;
}

.header-with-settings .settings-area svg {
  width: 20.359px;
  height: 21px;
  color: #838b9b;
}

.header-with-settings .settings-area .spinner-border {
  color: var(--Light-Blue);
}

.crm-filter-settings-modal .form-check-label {
  font-weight: 400;
}

.exclusion-radios {
  display: flex;
  gap: 12px;
}
