.fake-batch {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.fake-batch .bar {
  height: 12px;
  border-radius: 32px;
  background: var(--Grays-Light, #c1c5cd);
}

.fake-set {
  display: flex;
  width: 640px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 0px 24px;
}

.fake-set .fake-pic {
  width: 32px;
  height: 32px;
  background: var(--Pomona-Black, #5c6880);
  opacity: 0.25;
  border-radius: 32px;
}
