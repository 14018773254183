.engagement-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.engagement-list h1 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
}

.engagement-area-wrapper {
  display: flex;
  flex-direction: column;
  gap: -1px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid rgba(92, 104, 128, 0.25);
  background: var(--Plus-White, #fffefc);
}

.engagement-area-wrapper h2 {
  display: flex;
  padding: 16px;
  gap: 4px;
  justify-content: space-between;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  flex: 1 0 0;

  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  user-select: none;
  line-height: 20px; /* 125% */
}

.engagement-area-wrapper h2 span {
  display: flex;
  width: 28px;
  height: 22px;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: var(--Light-Red, #f67e7d);
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.engagement-list .action-items-wrapper {
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: -1px;
  align-self: stretch;
}

.engagement-list .action-item {
  display: flex;
  padding: 12px 16px 12px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 12px;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  cursor: pointer;
}

.engagement-list .action-item img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  user-select: none;
}

.engagement-list .action-item div:not(.fake-pic) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.engagement-list .action-item div h3 {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  user-select: none;
}

.engagement-list .action-item div p {
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  user-select: none;
}

.engagement-list .action-item span {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: rgba(246, 126, 125, 0.5);
  color: #000;
  font-family: 'Spline Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
  user-select: none;
}

.engagement-list .action-item span.green {
  background: rgba(39, 174, 96, 0.1);
}

.engagement-list .action-item span.amber {
  background: rgba(255, 214, 1, 0.1);
}

.engagement-list .action-item span.red {
  background: rgba(246, 126, 125, 0.5);
}

.engagement-list .more-approvals {
  color: var(--Dark-Blue, #086788);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */

  display: flex;
  height: 46px;
  padding: 12px 16px 12px 0px;

  border-top: 1px solid var(--Grays-Lighter, #e6e8eb);
  cursor: pointer;
  user-select: none;
}

.engagement-list .section .action-item img,
.engagement-list .section .action-item .fake-pic {
  height: 32px;
  width: 32px;
  border-radius: 32px;
}

.engagement-list .section .action-item .fake-pic .org-fake {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
