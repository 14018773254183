.crm-filter-set {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.add-crm-filter-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Pomona-Black, #5c6880);
  background: var(--Plus-White, #fffefc);
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.crm-filter-set .field-filter-group {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Grays-Medium, #838b9b);
  background: var(--White, #fff);
}

.crm-filter-set .field-filter-group .field-wrapper {
  flex: 1;
}

.crm-filter-set .field-filter-group .trash-icon-wrapper {
  width: 24px;
  height: 24px;
}

.crm-filter-set .field-filter-group .trash-icon-wrapper svg {
  color: #5c6880;
}

.crm-filter-set .and {
  color: var(--Wallstreet-Black, #000d26);
  text-align: center;
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.crm-filter-set .and:last-of-type {
  display: none;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.field-wrapper .field-topper {
  display: flex;
  gap: 8px;
}

.field-wrapper .field-topper > * {
  flex: 1;
}

.fixed-or-relative-date {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.crm-filter-set .date-radio-line,
.fixed-or-relative-date .radio-comparison {
  display: flex;
  gap: 12px;
  color: var(--Wallstreet-Black, #000d26);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.relative-date-inputs {
  display: flex;
  gap: 12px;
}

.relative-date-inputs > *:first-child {
  width: 120px;
}

.fixed-or-relative-date.crm {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--Grays-Light, #c1c5cd);
  background: #fff;
}

.engagement-types-area {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.fixed-or-relative-date .radio-comparison .form-check,
.fixed-or-relative-date .exclusion-radios .form-check {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fixed-or-relative-date .radio-comparison .form-check label,
.fixed-or-relative-date .exclusion-radios .form-check label {
  margin-top: 3px;
}
