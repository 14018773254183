.character-tracker {
  display: flex;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.character-tracker .right-side {
  display: flex;
  width: 191px;
  justify-content: flex-end;
  gap: 18px;
  align-items: center;
}

.right-side .state-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.right-side .state-wrapper span {
  color: var(--Performa-Black, #102040);
  font-family: 'Spline Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
